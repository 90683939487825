<template>
  <div id="top">
    <!-- Navbar start -->
    <div :class="[showname ? 'nav-container1' : 'nav-container2']" id="topnav">
      <div class="logo">
        <router-link to="/"
          ><img src="../assets/images/logo.png" alt="logo"
        /></router-link>
      </div>

      <div class="spacer"></div>
      <div class="nav-bar" id="mainListDiv">
        <ul>
          <li><router-link to="/" class="active">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/career">Careers</router-link></li>
          <li>
            <router-link to="/contactus">Contact us</router-link>
          </li>
          <li>
            <router-link to="/blog-post">Blog</router-link>
          </li>
          <li class="btn-small">
            <router-link to="/workwithus" class="a">Get started</router-link>
          </li>
          <li class="btn-small">
            <button @click.prevent="schedule_meeting()">Schedule a Call</button>
          </li>
        </ul>
      </div>
      <div class="buttons">
        <router-link to="/workwithus">Get started</router-link>
      </div>
      <div class="meeting-btn ml-5">
        <button @click.prevent="schedule_meeting()">Schedule a meeting</button>
      </div>

      <div className="nav-menu" id="mediaButton">
        <i
          @click="active"
          v-if="navbar"
          style="font-size: 21px"
          class="fa fa-bars text-white"
        ></i>
        <i
          @click="active1"
          style="font-size: 21px"
          v-if="navbar1"
          class="fa fa-times text-white"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";

export default defineComponent({
  /* eslint-disable */
  name: "Navbar",
  setup() {
    const state = reactive({
      navbar: true,
      navbar1: false,
    });
    const showname = ref(false);

    onMounted(() => {
      window.addEventListener("scroll", () => {
        if (window.scrollY === 0) {
          showname.value = true;
        } else {
          showname.value = false;
        }
      });
    });

    function schedule_meeting() {
      Calendly.showPopupWidget("https://calendly.com/grandilo-calistus/30mins");
      // return false;
    }

    function active() {
      var show = document.querySelector(".nav-bar");
      var nav = document.querySelector(".nav-bar ul");
      if ((show.style.width = "100%")) {
        state.navbar = false;
        state.navbar1 = true;
      }
      show.style.width = "100%";
      nav.style.display = "block";
    }
    function active1() {
      var show = document.querySelector(".nav-bar");
      var nav = document.querySelector(".nav-bar ul");
      show.style.width = "0%";
      nav.style.display = "none";
      if ((show.style.width = "0%")) {
        state.navbar = true;
        state.navbar1 = false;
      }
    }
    return {
      schedule_meeting,
      active,
      showname,
      ...toRefs(state),
      active1,
    };
  },
});
</script>

<style scoped>
.nav-container1 {
  background: rgba(17, 66, 61, 0.6);
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  height: 84px;
  margin: auto;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
}
.buttons a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  border: none;
  margin-right: -30px;
  outline: none;
  padding: 11px 35px 11px 35px;
  text-align: center;
  border: 1px solid #fff;
  color: #ffffff;
  background: transparent;
  border-radius: 5px;
}
.nav-container2 {
  background: rgb(8, 61, 55);
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  height: 84px;
  margin: auto;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
}
.nav-container1 div.nav-bar {
  display: flex;
  flex: 5;
}
.nav-container2 div.nav-bar {
  display: flex;
  flex: 5;
}
.spacer {
  flex: 2;
}
.logo {
  margin-left: 20px;
  flex: 2;
}
.logo img {
  color: #ffff;
  width: 126px;
}
div.nav-bar ul {
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 50px 0 0;

  padding: 0;
}
div.nav-bar ul li a {
  text-decoration: none;
  color: #ffff;
  padding: 0 20px;
  font-weight: 700;
  font-size: 12px;
  font-family: sans-serif;
}
div.nav-bar ul li a {
  text-decoration: none;
  color: #ffff;
  padding: 0 20px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  font-family: sans-serif;
}
div.nav-bar ul li a:hover {
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}
.nav-menu {
  display: none;
  z-index: 2;
  margin-right: 30px;
  text-align: center;
}
.meeting-btn {
  flex: 3;
  width: 10%;
}
.meeting-btn button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  background-color: #ffffff;
  color: #199a8b;
  border-radius: 5px;
}

.btn-small {
  display: none;
}
@media screen and (max-width: 917px) {
  .nav-bar {
    display: none;
  }
  .buttons a {
    display: none;
  }
  .nav-container2,
  .nav-container1 {
    height: 75px;
  }
  .logo {
    margin-left: 15px;
  }
  .logo img {
    width: 100px;
  }
  .nav-menu {
    display: block;
  }
  .meeting-btn {
    display: none;
  }
  .nav-container2 div.nav-bar,
  .nav-container1 div.nav-bar {
    width: 0%;
    top: 0;
    background-color: rgb(8, 61, 55);
    height: 86vh;
    position: absolute;
    margin: auto;
    left: 0;
    transition: all 0.5s ease-in-out;
  }
  div.nav-bar ul {
    text-align: left;
    display: none;
    margin: auto;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
  }
  div.nav-bar ul li:nth-child(3) {
    margin-bottom: 20px;
  }
  div.nav-bar ul li a,
  div.nav-bar ul li {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 16px;
  }
  .btn-small {
    display: block;
    margin-bottom: 10px;

    font-weight: 700;
    font-size: 16px;
  }
  .btn-small .a {
    background: #199a8b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 14px 22px;
    font-size: 16px;
    margin: 20px;
    text-transform: capitalize;
  }
  div.nav-bar ul li a:hover,
  .btn-small .a:hover {
    border: none;
    padding-bottom: 0;
  }

  .btn-small button {
    background: transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    outline: none;
    border: 2px solid #199a8b;
    padding: 12px 14px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    margin: 20px;
  }
  .nav-menu {
    display: block;
  }
}
</style>
