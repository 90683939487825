<template>
  <div>
    <Navbar />
    <div class="hire row">
      <div class="hire-text col-md-5 col-sm-12">
        <h1><span style="color: red">Hire</span> your technical team</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit
          deserunt nam odio tenetur labore sapiente recusandae iste quasi itaque
          consequuntur!
        </p>
      </div>
    </div>
    <div class="hire-btn" style="margin: auto">
      <div
        class="developer"
        :style="
          form1
            ? { background: 'none' }
            : { background: '#D3DAE1', color: '#6A6F71' }
        "
        @click="(form1 = true), (form2 = false), (form3 = false)"
      >
        Developer
      </div>
      <div
        class="designer"
        :style="
          form2
            ? { background: 'none' }
            : { background: '#D3DAE1', color: '#6A6F71' }
        "
        @click="(form1 = false), (form2 = true), (form3 = false)"
      >
        Designer
      </div>
      <div
        class="manager"
        :style="
          form3
            ? { background: 'none' }
            : { background: '#D3DAE1', color: '#6A6F71' }
        "
        @click="(form1 = false), (form2 = false), (form3 = true)"
      >
        Product / Project Manager
      </div>
    </div>

    <div class="btn-small">
      <select @change="change($event)">
        <option value="Developer">Hire a Developer</option>
        <option value="Designer">Hire a Designer</option>
        <option value="Manager">Hire a Manager</option>
      </select>
    </div>
    <div class="form">
      <div class="form-container">
        <DeveloperForm v-if="form1" />
        <DesignerForm v-if="form2" />
        <ProductDevForm v-if="form3" />
      </div>
    </div>
    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Navbar from "./Navbar.vue";
import DeveloperForm from "./Form/DeveloperForm.vue";
import DesignerForm from "./Form/DesignersForm.vue";
import ProductDevForm from "./Form/ProductDevForm.vue";
import Footer from "./Footer.vue";

export default defineComponent({
  /* eslint-disable */
  name: "Workwithus",
  components: {
    Navbar,
    Footer,
    DeveloperForm,
    DesignerForm,
    ProductDevForm,
  },
  setup() {
    const form1 = ref(true);
    const form2 = ref(false);
    const form3 = ref(false);
    function change(event) {
      if (event.target.value == "Designer") {
        form1.value = false;
        form2.value = true;
        form3.value = false;
      } else if (event.target.value == "Developer") {
        form1.value = true;
        form2.value = false;
        form3.value = false;
      } else if (event.target.value == "Manager") {
        form1.value = false;
        form2.value = false;
        form3.value = true;
      }
    }
    return {
      form1,
      form2,
      form3,
      change,
    };
  },
});
</script>

<style scoped>
.hire {
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(11, 71, 65, 0.45),
      rgba(11, 71, 65, 0.45)
    ),
    url(../assets/images/career2-min.jpg);
  width: 100%;
  margin: auto;
  height: 85vh;
}
.hire-text {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 60px;
  color: #ffffff;
  text-align: left;
}
.hire-text p {
  font-weight: 300;
  font-size: 20px;
}
.btn-small {
  margin-top: 50px;
  width: 90%;
  padding-left: 20px;
  display: none;
}
.btn-small select {
  padding: 15px;
  width: 58%;
  float: left;
  font-weight: 600;
  outline: none;
  font-size: 16px;
  border-radius: 3px;
  border: 0.5px solid #7b8180;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
.hire-text h1 {
  font-weight: 700;
  font-size: 60px;
}
.hire-img {
  height: 55vh;
  margin: auto;
  margin-right: 69px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-image: url(../assets/images/career2-min.jpg);
}
.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}
.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}
.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}
.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}
.hire-btn {
  display: flex;
  width: 90%;
  margin: auto;
}
.hire-btn div {
  height: 80px;
  width: 40%;
  background-color: rgb(207, 205, 205);
  color: rgb(82, 80, 80);
  font-weight: 700;
  font-size: 18px;
  padding: 25px;
  cursor: pointer;
  text-align: center;
}
.hire-btn div:first-child {
  border-right: 1px solid gray;
  border-left: 1px solid rgb(128, 128, 128, 0.2);
}
.hire-btn div:last-child {
  border-left: 1px solid gray;
  border-right: 1px solid rgb(128, 128, 128, 0.2);
}
.form {
  width: 55%;
  margin: auto;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
}
.form-container {
  display: flex;
  position: relative;
  transition: 0.2s ease-in-out;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 150px;
}
@media screen and (max-width: 807px) {
  .hire {
    height: 380px;
  }
  .hire-btn {
    display: none;
  }
  .btn-small {
    display: block;
  }
  .form {
    width: 100%;
    margin-top: -20px;
  }
  .form-container {
    margin-bottom: 30px;
  }
  .hire-img {
    height: 300px;
    width: 95%;
    margin: auto;
  }
  .hire-text {
    margin: auto;
    text-align: center;
    padding: 40px;
    padding-top: 70px;
    padding-left: 40px;
  }
  .hire-text p {
    font-size: 16px;
  }
  .hire-text h1 {
    font-size: 40px;
  }
  .hire-btn div {
    height: 60px;
    width: 40%;
    background-color: rgb(207, 205, 205);
    color: rgb(82, 80, 80);
    font-size: 12px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
  }
  .bottom {
    display: none;
  }
}
</style>
