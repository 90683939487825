import { FieldValue, serverTimestamp } from "firebase/firestore";

export class contactModel {
  email: string;
  phone: string;
  topic: string;
  message: string;
  fullname: string;
  createdAt: FieldValue;

  constructor() {
    this.email = "";
    this.phone = "";
    this.topic = "";
    this.message = "";
    this.fullname = "";
    this.createdAt = serverTimestamp();
  }
}
