import Swal from "sweetalert2";
import {AlertType} from "@/types/utils";

export default {
    sweetAlert(text: string, icon: AlertType = 'success', timer: number) {
        Swal.fire({
            text: text,
            icon: icon,
            showConfirmButton: true,
            timer: timer,
        });
    },
    sweetAlert2(title: string, text: string, icon: any, timer: number) {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showConfirmButton: true,
            timer: timer,
            confirmButtonColor: '#199a8b',
            iconColor: "#199a8b"
        });
    },
    sweetAlertConfirm(title: string) {
        Swal.fire({
            title: title,
            text: "You will not be able to recover this file after deleting",
            icon: "warning",
            confirmButtonColor: "#199a8b",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    },
};
