<template>
  <form class="hire-form" @submit.prevent="addDesigners()">
    <div class="form-item-last">
      <label style="font-weight: 600"
        >How many roles are you looking to fill?</label
      >
      <div class="radio">
        <input
          class="mr-2"
          type="radio"
          name="Role"
          value="1 Role"
          v-model="formData.designers.roles"
          checked
          id="16"
        /><label for="16" class="mr-4">1 Role</label>
        <input
          class="mr-2"
          type="radio"
          value="2-4 Role"
          v-model="formData.designers.roles"
          name="Role"
          id="17"
        /><label for="17" class="mr-4">2-4 Role</label>
        <input
          class="mr-2"
          type="radio"
          value="5+ Role"
          v-model="formData.designers.roles"
          name="Role"
          id="18"
        /><label for="18" class="mr-4">5+ Role</label>
      </div>
    </div>
    <div class="form-item-last">
      <label style="font-weight: 600"
        >What experience level are you looking for?</label
      >
      <div class="check">
        <div>
          <input
            type="checkbox"
            class="mr-2"
            name="0-2 years(Junior)"
            v-model="formData.designers.experience"
            value="0-2 years(Junior)"
            id="19"
          /><label for="19">0-2 years(Junior)</label>
        </div>
        <div>
          <input
            type="checkbox"
            class="mr-2"
            name="2-5 years(Mid)"
            v-model="formData.designers.experience"
            value="2-5 years(Mid)"
            id="20"
          /><label for="20">2-5 years(Mid)</label>
        </div>
        <div>
          <input
            type="checkbox"
            class="mr-2"
            name="5+ years(Senior)"
            v-model="formData.designers.experience"
            value="5+ years(Senior)"
            id="21"
          /><label for="21">5+ years(Senior)</label>
        </div>
        <span
          v-for="error in v$.experience.$errors"
          :key="error.$uid"
          class="error"
          >{{ error.$message }}</span
        >
      </div>
    </div>
    <div class="form-item-last">
      <label style="font-weight: 600">Area of specialization</label>
      <div class="check">
        <div>
          <input
            type="checkbox"
            class="mr-2"
            name="UI/UX Designer"
            v-model="formData.designers.areas"
            value="UI/UX Designer"
            id="22"
          /><label for="22">UI/UX Designer</label>
        </div>
        <div>
          <input
            type="checkbox"
            class="mr-2"
            name="Graphic Designer"
            v-model="formData.designers.areas"
            value="Graphic Designer"
            id="23"
          /><label for="23">Graphic Designer</label>
        </div>
        <div>
          <input
            type="checkbox"
            class="mr-2"
            name="3D Designer"
            v-model="formData.designers.areas"
            value="3D Designer"
            id="24"
          /><label for="24">3D Designer</label>
        </div>
        <span
          v-for="error in v$.areas.$errors"
          :key="error.$uid"
          class="error"
          >{{ error.$message }}</span
        >
      </div>
    </div>
    <h1 class="form-text">Contact information</h1>
    <div class="form-item">
      <label>Full Name</label>
      <input
        type="text"
        v-model="formData.designers.full_name"
        placeholder="Enter Full Name"
      />
      <span
        v-for="error in v$.full_name.$errors"
        :key="error.$uid"
        class="error"
        >{{ error.$message }}</span
      >
    </div>

    <div class="form-item">
      <label>Email</label>
      <input
        type="email"
        v-model="formData.designers.email"
        placeholder="Enter your email"
      />
      <span v-for="error in v$.email.$errors" :key="error.$uid" class="error">{{
        error.$message
      }}</span>
    </div>
    <div class="form-item">
      <label>Phone Number</label>
      <input
        type="tel"
        v-model="formData.designers.phone_num"
        placeholder="Enter Phone Number"
      />
      <span
        v-for="error in v$.phone_num.$errors"
        :key="error.$uid"
        class="error"
        >{{ error.$message }}</span
      >
    </div>
    <div class="form-item">
      <label>Company Name</label>
      <input
        type="text"
        v-model="formData.designers.company_name"
        placeholder="Enter your Company name"
      />
      <span
        v-for="error in v$.company_name.$errors"
        :key="error.$uid"
        class="error"
        >{{ error.$message }}</span
      >
    </div>
    <div class="form-item">
      <label>Specific Skills (Frameworks, Process...)</label>
      <textarea
        name="message"
        v-model="formData.designers.message"
        placeholder="Enter your message"
      ></textarea>
      <span
        v-for="error in v$.message.$errors"
        :key="error.$uid"
        class="error"
        >{{ error.$message }}</span
      >
    </div>

    <button class="mt-3" type="submit">{{ formData.loader }}</button>
  </form>
</template>
<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { store } from "@/store";
import { hireModel } from "@/models/hire";
import router from "@/router";
import Util from "@/util/Util";

export default defineComponent({
  name: "DesignersForm",
  setup() {
    const formData = reactive({
      designers: new hireModel(),
      loader: "Submit Request",
    });

    const rules = computed(() => {
      return {
        email: { required, email },
        phone_num: { required },
        areas: { required },
        message: { required },
        company_name: { required },
        full_name: { required },
        roles: { required },
        experience: { required },
      };
    });

    const v$ = useVuelidate(rules, formData.designers);

    const loading = computed(() => store.getters(["designers/getLoading"]));

    async function addDesigners() {
      const result = await v$.value.$validate();
      if (!result) {
        console.log("");
      } else {
        try {
          formData.loader = "Submitting.....";
          await store.dispatch("designers/submitDes", formData.designers);
          formData.designers = new hireModel();

          Util.sweetAlert2(
            "Success Sent",
            "We will get back to you in less than 24 hours",
            "success",
            7000
          );
          formData.loader = "Submit Request";
          await router.push({ path: "/" });
        } catch (err: any) {
          formData.loader = "Submit Request";
          Util.sweetAlert2("Error Encountered", err.message, "error", 4000);
        }
      }
    }
    return {
      addDesigners,
      formData,
      loading,
      v$,
      rules,
    };
  },
});
</script>
<style scoped>
.hire-form {
  width: 100%;
  margin: auto;
  padding: 40px;
  border-radius: 3px;
}

.hire-form .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.form-item-last {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}
.form-item-last label {
  text-align: left;
  font-size: 16px;
  color: #050505;
  font-family: sans-serif;
  font-weight: 500;
}
input[type="radio"] {
  width: 14px;
  height: 14px;
  appearance: none;
  border: 1px solid #353636;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
}
input[type="radio"]:checked {
  background-color: #009688;
}
input[type="checkbox"] {
  accent-color: #009688;
}
.check {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
}
.check div {
  margin-right: 10px;
}
.form-item label {
  text-align: left;
  font-size: 16px;
  color: #0c0b0b;
  font-family: sans-serif;
  font-weight: 600;
}
.form-item select {
  outline: none;
  margin-top: -5px;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
  border: none;
  background-color: #ececec;
}
.hire-item select option,
.hire-item select,
.hire-item input::placeholder {
  font-size: 14px;
}
.form-item input,
.form-item textarea {
  border-radius: 6px;
  margin-top: -5px;
  width: 100%;
  font-size: 14px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  border: none;
  background-color: #ececec;
}

.form-text {
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: left;
}

.hire-form button {
  width: 150px;
  float: left;
  text-align: center;
  padding: 8px;
  font-weight: 600;
  outline: none;
  background-color: #009688;
  color: #fff;
  border: 1px solid #009688;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.hire-form button:hover {
  background: #ffffff;
  color: #009688;
}
.error {
  color: rgb(201, 6, 6);
  font-size: 12px;
  text-align: left;
}
@media screen and (max-width: 807px) {
  .hire-form button {
    float: none;
  }
  .hire-form {
    padding: 20px;
  }
}
</style>
