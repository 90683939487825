<template>
  <router-view />
</template>

<style>
#app {
  font-family: "lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  background-color: #f5f5f5;
}
</style>
