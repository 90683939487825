<template>
  <div>
    <Navbar />
    <div class="work">
      <div class="work-text">
        <h1>We have received your Proposal.</h1>
        <br />
        <p class="ml-2">
          We shall go through and revert back to you in the next 48 hours. In
          the mean time feel free to book an online appointment meeting with us
          using the button below.
        </p>

        <div class="meeting-btn">
          <button @click.prevent="schedule_meeting">Schedule a meeting</button>
        </div>
      </div>
    </div>

    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";

export default defineComponent({
  /* eslint-disable */
  name: "ThankYou",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const state = reactive({});

    function schedule_meeting() {
      (window as any).Calendly.showPopupWidget(
        "https://calendly.com/grandilo-calistus/30mins"
      );
    }

    return {
      schedule_meeting,

      ...toRefs(state),
    };
  },
});
</script>

<style scoped>
.meeting-btn button {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  background-color: #199a8b;
  color: #ffffff;
  border-radius: 5px;
}

.work .work-text {
  width: 70%;
  margin: auto;
  padding-top: 180px;
  padding-bottom: 20px;
  text-align: left;
}

.work-text h1 {
  font-style: normal;
  color: #000;
  font-weight: 700;
  font-size: 72px;
}

.work-text p {
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 20px;
  margin-top: -12px;
}

.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}

.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}

.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}

.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}

@media screen and (max-width: 807px) {
  .work {
    height: 425px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .work .work-text {
    margin: auto;
    padding-top: 180px;
    width: 100%;
    text-align: center;
  }

  .work-text h1 {
    font-size: 30px;
  }

  .work-text p {
    font-size: 15px;
  }

  .bottom {
    display: none;
  }
}
</style>
