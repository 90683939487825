<template>
    <div>
        <Navbar/>
        <div class="row" :class="[opened ? 'career' : 'career1']">
            <div class="career-text col-md-8">
                <h1>Join GRANDILO</h1>
                <p>We are always on the look out for talent</p>
            </div>
            <!-- <div class="career-img container col-md-5"></div> -->
        </div>
        <div class="career-btn" style="margin: auto">
            <div
                    class="open"
                    :style="
          opened
            ? { background: '#D3DAE1', color: '#6A6F71' }
            : { background: 'none' }
        "
                    @click="opened = false"
            >
                Current Opening
            </div>
            <div
                    class="interns"
                    :style="
          opened
            ? { background: 'none' }
            : { background: '#D3DAE1', color: '#6A6F71' }
        "
                    @click="opened = true"
            >
                Internship Program
            </div>
        </div>
        <div class="btn-small">
            <select @change="change($event)">
                <option value="Interns">Internship Program</option>
                <option value="Current">Current Openings</option>
            </select>
        </div>
        <p class="p-small" v-if="!opened">
            We are always on the look out for talents, apply within.
        </p>
        <div class="opened" v-if="opened">
            <div class="eligible row">
                <div class="eligible-text col-md-5 col-sm-12">
                    <h1>Eligibility Criteria</h1>
                    <ul>
                        <li>Passionate about technology</li>
                        <li>Have basic understanding of algorithm and data structures</li>
                        <li>Have active internet</li>
                        <li>Have stable electricity from 9am to 5pm(GTM+1)</li>
                    </ul>
                </div>
                <div class="eligible-img col-md-6 col-sm-12"></div>
            </div>
            <div class="center">
                <span></span>
                <span></span>
            </div>
            <div class="eligible eligible2 row">
                <div class="eligible-img2 col-md-6 col-sm-12"></div>

                <div class="eligible-text margin col-md-5 col-sm-12">
                    <h1>Benefits</h1>
                    <ul>
                        <li>Learn from top Software Engineers</li>
                        <li>
                            Opportunity to work on real life project with thousands of users
                        </li>
                        <li>Monthly stipend from forth month</li>
                        <li>
                            Opportunity to make a positive impact in the world using technology
                        </li>
                    </ul>
                </div>
                <div class="eligible-img3 col-md-6 col-sm-12"></div>
            </div>

            <div class="interns">
                <h1 class="form-text" style="margin-top: 100px">Internship Tracks</h1>
                <div class="inters-items">
                    <div class="item">
                        <img
                                src="../assets/images/Vector6.svg"
                                alt=""
                                width="50"
                                height="50"
                        />
                        <h1>Frontend Development</h1>
                    </div>
                    <div class="item">
                        <p style="color: #199a8b; font-weight: 900">&lt;/></p>
                        <h1>Backend Development</h1>
                    </div>
                    <div class="item">
                        <img
                                src="../assets/images/Vector8.svg"
                                alt=""
                                width="50"
                                height="50"
                        />
                        <h1>Mobile App Development</h1>
                    </div>
                    <div class="item">
                        <img
                                src="../assets/images/Vector7.svg"
                                alt=""
                                width="50"
                                height="50"
                        />
                        <h1>Soft Skills</h1>
                    </div>
                </div>
            </div>
        </div>
        <h1 class="form-text">Application Form</h1>
        <div class="form">
            <form class="career-form" @submit.prevent="addCareer">
                <h1 class="form-text2">Application Form</h1>
                <div class="form-item">
                    <label for="fullname">Full Name</label>
                    <input
                            type="text"
                            v-model="career.fullName"
                            placeholder="Enter Full Name"
                            id="fullname"
                    />
                    <span
                            v-for="error in v$.fullName.$errors"
                            :key="error.$uid"
                            class="error"
                    >{{ error.$message }}</span
                    >
                </div>

                <div class="form-item">
                    <label for="email">Email</label>
                    <input
                            type="email"
                            v-model="career.email"
                            placeholder="Enter your email"
                            id="email"
                    />
                    <span
                            v-for="error in v$.email.$errors"
                            :key="error.$uid"
                            class="error"
                    >{{ error.$message }}</span
                    >
                </div>

                <div class="form-item">
                    <label for="number">Phone Number</label>
                    <input
                            type="tel"
                            v-model="career.phone"
                            placeholder="Enter Phone Number"
                            id="number"
                    />
                    <span
                            v-for="error in v$.phone.$errors"
                            :key="error.$uid"
                            class="error"
                    >{{ error.$message }}</span
                    >
                </div>

                <div class="form-item">
                    <label for="Resume">Resume</label>
                    <div class="resume-btn">
                        <input
                                type="button"
                                @click.prevent="$refs.fileinput.click()"
                                value="Browse Files"
                        />

                        <input
                                style="display: none"
                                type="file"
                                @change="handleMultipleImageUpload"
                                ref="fileinput"
                                placeholder=""
                                id="Resume"
                                accept="application/pdf"
                                multiple
                        />
                    </div>
                    <ul class="list-group mt-2 mb-2" v-if="multi_pdf_files.length > 0">
                        <li class="list-group-item d-flex justify-content-between align-items-center"
                            v-for="(file, index) in multi_pdf_files" :key="index">
                            {{ file.name }}
                            <span class="badge badge-danger badge-pill" @click="multi_pdf_files.splice(index, 1)"><i
                                    class="fa fa-times"></i></span>
                        </li>
                    </ul>
                    <!-- <span
                      v-for="error in v$.name.$errors"
                      :key="error.$uid"
                      class="error"
                      >{{ error.$message }}</span
                    > -->
                </div>

                <div class="form-item">
                    <label for="profile">LinkedIn Profile Url (optional)</label>
                    <input
                            type="url"
                            v-model="career.linkedin"
                            placeholder="Profile link"
                            id="profile"
                    />
                    <span
                            v-for="error in v$.linkedin.$errors"
                            :key="error.$uid"
                            class="error"
                    >{{ error.$message }}</span
                    >
                </div>

                <div class="form-item">
                    <label for="profile1">Github Profile Url (optional)</label>
                    <input
                            type="url"
                            v-model="career.github"
                            placeholder="Profile link"
                            id="profile1"
                    />
                    <span
                            v-for="error in v$.github.$errors"
                            :key="error.$uid"
                            class="error"
                    >{{ error.$message }}</span
                    >
                </div>

                <div class="form-item">
                    <label for="profile2">Internship Track</label>
                    <select name="" v-model="career.interest" id="profile2">
                        <option value="">Select a Track</option>
                        <option value="frontend">Front-End Development(VueJS)</option>
                        <option value="backend">Back-end Development(NodeJS)</option>
                        <option value="android">Android Development(Flutter)</option>
                    </select>
                    <span
                            v-for="error in v$.interest.$errors"
                            :key="error.$uid"
                            class="error"
                    >{{ error.$message }}</span
                    >
                </div>
                <button class="mt-3" type="submit">{{ loader }}</button>
            </form>
            <div class="bottom">
                <span></span>
                <span></span>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, toRefs, ref} from "vue";
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import {InternshipModel} from "@/models/internshipCareer";
import {store} from "@/store";
import router from "@/router";
import Util from "@/util/Util";

export default defineComponent({
    /* eslint-disable */
    name: "Career",
    components: {
        Navbar,
        Footer,
    },
    setup() {
        const multi_pdf_files = ref([] as File[]);
        const state = reactive({
            opened: true,
            career: new InternshipModel(),
            file: "",
            loader: "Submit Form",
        });

        const rules = computed(() => {
            return {
                fullName: {required},
                email: {required, email},
                phone: {required},
                interest: {required},
                linkedin: {required},
                github: {required},
            };
        });

        const loading = computed(() => store.getters["internshipCareer/getLoading"]);
        const v$ = useVuelidate(rules, state.career);

        const handleMultipleImageUpload = (event: any) => {
            multi_pdf_files.value = [...multi_pdf_files.value, ...event.target.files];
        }

        async function addCareer() {
            const result = await v$.value.$validate();
            if (!result) {
                return Util.sweetAlert('Incomplete information!!! Please fill up all required information and re-submit', 'info', 40000);
            } else if (multi_pdf_files.value.length <= 0) {
                return Util.sweetAlert('Upload your Resume in pdf format', 'info', 40000);
            } else {
                try {
                    state.loader = "Submitting.....";
                    await store.dispatch("internshipCareer/submitCareer", [state.career, multi_pdf_files.value]);
                    state.career = new InternshipModel();

                    Util.sweetAlert2(
                        "Success Sent",
                        "We will get back to you in less than 24 hours",
                        "success",
                        70000
                    );
                    state.loader = "Submit Form";
                    await router.push({path: "/"});
                } catch (err: any) {
                    state.loader = "Submit Form";
                    Util.sweetAlert2("Error Encountered", err.message, "error", 40000);
                }
            }
        }

        function change(event: any) {
            if (event.target.value == "Current") {
                state.opened = false;
            } else if (event.target.value == "Interns") {
                state.opened = true;
            }
        }

        return {
            ...toRefs(state),
            v$,
            loading,
            rules,
            change,
            handleMultipleImageUpload,
            addCareer,
            multi_pdf_files,
        };
    },
});
</script>

<style scoped>
.career {
    background: #04332f;
    height: 85vh;
    width: 100%;
    margin: auto;
    color: #ffff;
}

.career1 {
    background: linear-gradient(
            to bottom,
            rgba(11, 71, 65, 0.45),
            rgba(11, 71, 65, 0.45)
    ),
    url(../assets/images/cytonn-photography-n95VMLxqM2I-unsplash.svg);
    height: 85vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    margin: auto;
    color: #ffff;
}

.career-text {
    padding: 180px;
    width: 100%;
    margin: auto;
    padding-left: 20px;
    text-align: left;
}

.career-text h1 {
    font-style: normal;
    color: #fbfbfb;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 70px;
}

.career-text p {
    font-weight: 400;
    font-size: 20px;
    margin-top: -12px;
}

.career-img {
    height: 55vh;
    margin: auto;
    margin-right: 65px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../assets/images/cytonn-photography-n95VMLxqM2I-unsplash.svg);
}

.btn-small {
    margin-top: 50px;
    padding-bottom: 20px;
    width: 90%;
    padding-left: 20px;
    display: none;
}

.btn-small select {
    padding: 15px;
    float: left;
    width: 58%;
    font-weight: 600;
    outline: none;
    font-size: 16px;
    border-radius: 3px;
    border: 0.5px solid #7b8180;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.p-small {
    font-weight: 400;
    font-size: 16px;
    padding: 20px 50px;
    text-align: center;
}

.career-btn {
    display: flex;
    width: 88%;
    color: rgb(82, 80, 80);
    font-weight: 700;
    font-size: 18px;
    /* background: rgb(207, 205, 205); */
    font-weight: 600;
}

.career-btn div:first-child {
    height: 89px;
    width: 50%;
    padding: 25px;
    padding-top: 35px;
    cursor: pointer;
    text-align: right;
}

.career-btn div:last-child {
    height: 89px;
    width: 50%;
    padding: 25px;
    padding-top: 35px;
    cursor: pointer;
    text-align: left;
}

.career-btn div:first-child {
    border-right: 1px solid gray;
    border-left: 1px solid rgb(128, 128, 128, 0.1);
}

.career-btn div:last-child {
    border-right: none;
    border-right: 1px solid rgb(129, 128, 128, 0.1);
}

.btns {
    width: 100%;
    margin: auto;
    height: 40px;
}

.btns span {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
    color: #000000;
}

.btns span:hover {
    background-color: #d3dae1;
}

.eligible {
    width: 88%;
    display: flex;
    justify-content: center;
    margin: auto;
    height: 450px;
    margin-top: 70px;
    margin-bottom: 40px;
}

.eligible2 {
    width: 88%;
    display: flex;
    margin: auto;
    height: 370px;
    margin-top: 70px;
    margin-bottom: 40px;
}

.eligible-text {
    margin: auto;
    margin-left: -15px;
    text-align: left;
}

.eligible-text h1 {
    text-align: left;
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    color: #000000;
}

.eligible-text ul {
    margin-left: -20px;
}

.eligible-text li {
    text-align: left;
    font-weight: 300;
    font-size: 24px;
}

.margin {
    margin-left: 70px;
}

.eligible-img {
    z-index: 1;
    border-radius: 4px;
    margin-right: 25px;
    background-image: url(../assets/images/pexels-lukas-574071.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.eligible-img2 {
    z-index: 1;
    background-image: url(../assets/images/IMG_7275.svg);
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.eligible-img3 {
    z-index: 1;
    display: none;
    background-image: url(../assets/images/IMG_7275.svg);
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.center {
    text-align: center;
    display: flex;
    z-index: -1;
    justify-content: center;
    margin-top: -150px;
    margin-left: 170px;
    overflow: hidden;
    margin-bottom: -150px;
}

.center span {
    width: 13px;
    height: 300px;
    transform: rotate(45deg);
    background-color: #199a8b;
}

.center span:first-child {
    margin-right: 45px;
    border-top-right-radius: 20px;
}

.center span:last-child {
    margin-right: 50px;
    border-top-right-radius: 20px;
}

.inters-items {
    width: 88%;
    display: flex;
    margin: auto;
}

.inters-items .item {
    border: 2px solid rgba(0, 150, 136, 0.23);
    border-radius: 7px;
    width: 25%;
    height: 150px;
    padding: 15px;
    margin: 10px;
}

.inters-items .item h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    font-family: sans-serif;
    font-weight: 600;
}

.inters-items .item p,
.inters-items .item img {
    padding: 10px;
}

.form {
    background: #263238;
    width: 100%;
}

.career-form {
    width: 55%;
    margin: auto;
    height: auto;
    padding: 40px;
    margin-bottom: 150px;
    border-radius: 3px;
}

.career-form .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-item label {
    text-align: left;
    font-size: 15px;
    color: #ffff;
    font-family: sans-serif;
    font-weight: 500;
}

.form-item input,
.form-item textarea {
    border-radius: 6px;
    margin-top: -5px;
    font-size: 14px;
    width: 100%;
    padding: 9px;
    outline: none;
    border: none;
    background-color: #ececec;
}

input[type="button"] {
    width: 25%;
    float: left;
    border: none;
    font-weight: 600;
    font-size: 14px;
    background-color: #dfdddd;
    color: rgb(43, 39, 39);
}

.form-item select {
    outline: none;
    font-size: 14px;
    margin-top: -5px;
    border-radius: 6px;
    padding: 10px;
    padding-left: 20px;
    width: 100%;
    border: none;
    background-color: #ececec;
}

.form-text {
    color: black;
    margin-top: 50px;
    margin-bottom: 30px;
    font-weight: 200;
    font-size: 40px;
    text-align: center;
}

.p-small,
.form-text2 {
    display: none;
}

.career-form button {
    width: 150px;
    float: left;
    text-align: center;
    padding: 8px;
    font-weight: 600;
    outline: none;
    background-color: #009688;
    color: #fff;
    border: 1px solid #009688;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.career-form button:hover {
    background: #ffffff;
    color: #009688;
}

.bottom {
    text-align: right;
    display: flex;
    justify-content: right;
    margin-top: -150px;
    overflow: hidden;
    margin-bottom: -150px;
}

.bottom span {
    width: 13px;
    height: 200px;
    transform: rotate(45deg);
    background-color: #fff;
}

.bottom span:first-child {
    height: 350px;
    margin-right: 45px;
    margin-top: -40px;
    border-top-right-radius: 20px;
}

.bottom span:last-child {
    height: 250px;
    margin-right: 50px;
    border-top-right-radius: 20px;
}

.error {
    color: rgb(201, 6, 6);
    font-size: 12px;
    text-align: left;
}

@media screen and (max-width: 807px) {
    .bottom {
        display: none;
    }

    .btn-small {
        display: block;
    }

    .p-small {
        display: block;
    }

    .career1,
    .career {
        height: 284px;
    }

    .career-text {
        padding: 10px;
        padding-top: 80px;
        text-align: center;
    }

    .career-text h1 {
        font-size: 40px;
    }

    .career-text p {
        font-size: 16px;
    }

    .career-btn {
        display: none;
    }

    .eligible {
        width: 100%;
    }

    .center {
        display: none;
    }

    .eligible,
    .eligible2 {
        height: auto;
    }

    .margin {
        margin-left: 0px;
    }

    .eligible-text {
        margin: auto;
        margin-left: 15px;
    }

    .eligible-text h1 {
        text-align: left;
        font-size: 28px;
    }

    .eligible-text ul {
        margin-left: -30px;
    }

    .eligible-text li {
        text-align: left;
        font-size: 20px;
    }

    .eligible-img {
        height: 279.96px;
        width: 90%;
        margin: auto;
    }

    .eligible-img3 {
        display: block;
        width: 90%;
        margin: auto;
        height: 279.96px;
    }

    .eligible-img2 {
        display: none;
    }

    .inters-items {
        width: 88%;
        flex-direction: column;
    }

    .inters-items .item {
        width: 80%;
        margin: auto;
        margin-bottom: 15px;
    }

    .interns {
        margin-bottom: 70px;
    }

    .form-text {
        font-size: 28px;
        display: none;
        font-weight: 600;
    }

    .form-text2 {
        font-size: 28px;
        display: block;
        color: #fff;
        padding: 0 0 30px 0;
        font-weight: 600;
    }

    .form {
        padding-bottom: 40px;
    }

    .career-form {
        width: 100%;
        margin: auto;
        padding: 40px;
        margin-bottom: 15px;
    }

    input[type="button"] {
        width: 40%;
    }

    .career-form button {
        float: none;
    }

    .career-form .form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

.fa-times:hover {
    cursor: pointer;
    opacity: 0.8;
}

.list-group .list-group-item .badge-pill:hover {
    cursor: pointer;
}
</style>
