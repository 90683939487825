import contact from "@/store/modules/contact";
import booking from "./modules/booking";
import internshipCareer from "./modules/internshipCareer";
import hire from "./modules/hire";
import auth from "./modules/auth";
import blog from "./modules/blog";
import designers from "./modules/designers";
import productdesigner from "./modules/productdesigner";
import workwithus from "./modules/workwithus";
import utilFunctions from "@/store/modules/utilFunctions";
import {createStore} from "vuex";

export const store = createStore({
    modules: {
        contact,
        internshipCareer,
        hire,
        blog,
        productdesigner,
        designers,
        booking,
        workwithus,
        auth,
        utilFunctions,
    },
});
