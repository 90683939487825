export function useFuncUtils(){
    const extractPlainText =  (html: string): string => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent||'';
    }

    return {
        extractPlainText
    }
}