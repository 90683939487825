import {deleteObject, getDownloadURL, ref, uploadBytes} from "@firebase/storage";
import {storage} from "@/config/firebase";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import imageCompression from 'browser-image-compression';

const state = {};

const getters = {};
const mutations = {};
const actions = {
    async handleImageCompression(image: File): Promise<File> {

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(image, options);
            resolve(compressedFile)
        })
    },

    async uploadImageToFirebase({dispatch}: any, [file, folder]: [File, string]): Promise<string> {
        //compress image that are more than 1mb in size
        file = ((file.size / 1024 / 1024) > 1) ? await dispatch('handleImageCompression', file) : file;
        return new Promise((resolve) => {
            try {
                const fileExt = file.name.split('.').pop();
                let [fileName] = file.name.split(" ").join('').split('.');
                fileName = fileName + "" + Date.now() + `.${fileExt}`;
                const storageRef = ref(storage, `${folder}/` + fileName);
                uploadBytes(storageRef, file)
                    .then((uploadTask) => {
                        return getDownloadURL(uploadTask.ref)
                    })
                    .then((imageURL) => {
                        resolve(imageURL);
                    });
            } catch (e) {
                console.log(e);
                resolve('')
            }

        })
    },
    async uploadPDFToFirebase(context:any, [file, folder]: [File, string]) {

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: 'application/pdf'
            };

            let [fileName] = file.name.split(" ").join('').split('.');
            fileName = fileName + "" + Date.now()
            //.replace(/\s/g,'')
            const storageRef = ref(storage, `${folder}/` + fileName);
            const uploadTask = await uploadBytes(storageRef, file, metadata);
            const imageURL = await getDownloadURL(uploadTask.ref);
            resolve(imageURL);
        })

    },
    async deleteImageFromStorage(url: string) {
        try {
            // Create a reference to the file to delete
            const imageRef = ref(storage, url);

            await deleteObject(imageRef)
        } catch (e: any) {
            console.log(e.message)
        }
        return Promise.resolve(null)
    }
};
const modules = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
