import { contactUsRef } from "@/config/firebase";
import { contactModel } from "@/models/contact";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";

const state = {
  contact: [],
  singleContact: { id: "", data: {} },
  loading: false,
};

const getters = {
  getLoading: (state: any) => state.loading,
  getContact: (state: any) => state.contact,
  getSingleContact: (state: any) => state.singleContact,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setContact(state: any, payload: any) {
    state.contact = payload;
  },
  setSingleContact(state: any, payload: any) {
    state.singleContact = payload;
  },
};
const actions = {
  async submitContact({ commit }: any, contact: any) {
    commit("setLoading", true);
    try {
      await addDoc(contactUsRef, Object.assign({}, contact));
      commit("setLoading", false);
      return Promise.resolve(null);
    } catch (e) {
      commit("setLoading", false);
      return Promise.reject(e);
    }
  },
  async getContact({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(contactUsRef, orderBy("createdAt", "desc"));
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setContact", trr_arr);
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSingleContact({ commit }: any, id: any) {
    const docRef = doc(contactUsRef, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setSingleContact", { id: Snapshot.id, data: Snapshot.data() });
    }
  },
  async deleteContact(id: any) {
    id = state.singleContact.id;
    try {
      const docRef = doc(contactUsRef, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
