<template>
  <div>
    <Navbar />
    <div class="head" v-if="blog.id">
      <div class="top-header">
        <h3>
          {{ blog.data.description }}
        </h3>
        <p>{{ new Date(blog.data.createdAt.seconds * 1000).toDateString() }}</p>
        <hr />
      </div>
      <div class="top-story">
        
        <div class="top-img">
          <div
            :style="{ backgroundImage: `url(${blog.data.displayURL})` }"
            class="img"
          ></div>
        </div>
        <div class="socials">
          <button @click="twitter">
            <img
              src="../assets/icons/x.png"
              style="margin-right: 7px"
              width="15"
              alt=""
            />
            share
          </button>
          <button @click="facebook">
            <img
              src="../assets/icons/facebook.png"
              width="15"
              style="margin-right: 7px"
              alt=""
            />
            share
          </button>
          <button @click="whatsapp">
            <img
              src="../assets/icons/whatsapp.png"
              width="17"
              style="margin-right: 7px"
              alt=""
            />
            share
          </button>
        </div>
      </div>

      <div class="story">
        <div class="story1"></div>
        <div class="story2" v-html="blog.data.body"></div>
      </div>
    </div>
    <!-- <p>{{ blog.data.body }}</p> -->
    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, reactive, computed } from "vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  /* eslint-disable */
  name: "Blog_Details",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({});
    const blog = computed(() => store.getters["blog/getSingleBlog"]);

    function facebook() {
      const navUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href} `;
      window.open(navUrl, "_blank");
    }
    function twitter() {
      const navUrl = `https://www.twitter.com/intent/tweet?text=${window.location.href}`;
      window.open(navUrl, "_blank");
    }
    function whatsapp() {
      const navUrl = `https://api.whatsapp.com/send?text=${window.location.href} `;
      window.open(navUrl, "_blank");
    }

    store.dispatch("blog/getSingleBlog", route.params.id);

    return {
      ...toRefs(state),
      facebook,
      twitter,
      whatsapp,
      blog,
    };
  },
});
</script>

<style scoped>
.head {
  padding-top: 150px;
  width: 90%;
  margin: auto;
}
.top-header {
  text-align: left;
  padding: 30px 0;
}
.top-header h3 {
  font-weight: bold;
  font-size: 40px;
}
.top-header hr {
  color: red;
}
.top-story {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.socials {
  width: 20%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}
.socials button {
  font-size: 12px;
  padding: 6px 12px;
  outline: none;
  margin-bottom: 30px;
}
.top-img {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
}
.top-img .img {
  width: 100%;
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: contain;
}
.top-img h4 {
  font-style: italic;
  text-align: left;
  font-size: 19px;
}
.story {
  display: flex;
  justify-content: space-between;
}
.story2 {
  width: 50%;
  margin: auto;
  margin-top: 20px;
  line-height: 1.3;
  padding: 20px;
}
.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}
.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}
.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}
.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}

@media screen and (max-width: 807px) {
  .top-story {
    width: 100%;
    margin: auto;
    flex-direction: column;
  }
  .top-img .img {
    width: 100%;
    height: 40vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .top-img {
    width: 100%;
  }
  .socials {
    flex-direction: row;
    padding: 10px;
    width: 90%;
    justify-content: space-between;
  }
  .socials i {
    font-size: 20px;
    padding-right: 20px;
    padding-bottom: 0;
  }
  .story2 {
    width: 100%;
  }
  .story {
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
  .head {
    margin-bottom: 120px;
  }
  /* .story2 >>> img {
    width: 100%;
  } */

  .top-header h3 {
    font-size: 25px;
  }
}
</style>
