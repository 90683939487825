import { blogRef, storage } from "@/config/firebase";
import { blogModel } from "@/models/blog";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

const state = {
  blog: [],
  blogs: [],
  singleBlog: { id: "", data: {} },
  loading: false,
};

const getters = {
  getLoading: (state: any) => state.loading,
  getBlog: (state: any) => state.blog,
  getFiveBlog: (state: any) => state.blogs,
  getSingleBlog: (state: any) => state.singleBlog,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setBlog(state: any, payload: any) {
    state.blog = payload;
  },
  setFiveBlog(state: any, payload: any) {
    state.blogs = payload;
  },
  setSingleBlog(state: any, payload: any) {
    state.singleBlog = payload;
  },
};
const actions = {
  async submitBlog({ commit, dispatch }: any, { blog, display }: any) {
    commit("setLoading", true);
    try {
      const storage = getStorage();
      const storageRef = ref(storage, "blog/image/" + display.name);

      const uploadTask = await uploadBytes(storageRef, display);
      const imageURL = await getDownloadURL(uploadTask.ref);
      blog.displayURL = imageURL;
      console.log(display);

      await addDoc(blogRef, Object.assign({}, blog));
      commit("setLoading", false);
      return Promise.resolve(null);
    } catch (e) {
      commit("setLoading", false);
      return Promise.reject(e);
    }
  },

  async deleteImageFromStorage(url: any) {
    try {
      console.log(url.state.singleBlog.data.displayURL);

      const storage = getStorage();
      const imageRef = ref(storage, url.state.singleBlog.data.displayURL);

      await deleteObject(imageRef);
    } catch (e: any) {
      console.log(e.message);
    }
  },

  async getBlog({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(blogRef, orderBy("createdAt", "desc"));
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setBlog", trr_arr);
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getFiveBlogs({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(blogRef, orderBy("createdAt", "desc"));
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setFiveBlog", trr_arr.slice(0, 4));
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSingleBlog({ commit }: any, id: any) {
    const docRef = doc(blogRef, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setSingleBlog", { id: Snapshot.id, data: Snapshot.data() });
    }
  },
  async updateBlog({ state }: any, { blog, display }: any) {
    const id = blog.id;

    try {
      if (display) {
        const storage = getStorage();
        const storageRef = ref(storage, "blog/image/" + display.name);

        const uploadTask = await uploadBytes(storageRef, display);
        const imageURL = await getDownloadURL(uploadTask.ref);
        blog.data.displayURL = imageURL;
    }
       
        const docRef = doc(blogRef, id);
        await updateDoc(docRef, Object.assign({}, blog.data));
        return Promise.resolve(null);
      
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteBlog(id: any) {
    id = state.singleBlog.id;
    try {
      const docRef = doc(blogRef, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
