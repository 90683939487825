import { initializeApp } from "firebase/app";
import { getFirestore, collection, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// import {getDatabase} from 'firebase/database'

let config;
if (
  window.location.hostname === "grandilo.com" ||
  window.location.hostname === "www.grandilo.com"
) {
  config = {
    apiKey: "AIzaSyCNVX7tqz7wGCu7644er8UYVay0HsrS-fM",
    authDomain: "grandilo-a87d9.firebaseapp.com",
    databaseURL: "https://grandilo-a87d9.firebaseio.com",
    projectId: "grandilo-a87d9",
    storageBucket: "grandilo-a87d9.appspot.com",
    messagingSenderId: "833060662401",
  };
} else {
  config = {
    apiKey: "AIzaSyBp2S0hZCFboPG6AuuCRF0gvc46_JkdNVE",
    authDomain: "grandilo-staging.firebaseapp.com",
    projectId: "grandilo-staging",
    storageBucket: "grandilo-staging.appspot.com",
    messagingSenderId: "238709388851",
    appId: "1:238709388851:web:bc44476f6bafa085685546",
    measurementId: "G-7WK5D4PF9E",
  };
}

export const app = initializeApp(config);

// const database = getDatabase(app);
// export const db = ref(database);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const firestoreTimeStamp = serverTimestamp();
export const contactUsRef = collection(db, "contactUs");
export const bookingRef = collection(db, "Booking");
export const productDevRef = collection(db, "productDevRequest");
export const DesignersDevRef = collection(db, "DesignersRequest");
export const DeveloperRef = collection(db, "DeveloperRequest");
export const workWithUs = collection(db, "WorkWithUs");
export const internshipCareerRef = collection(db, "training");
export const blogRef = collection(db, "Blog");
