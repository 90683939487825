import {Timestamp, type DocumentData} from "firebase/firestore";
import type {InternshipStatus} from "@/util/util.types";

export class InternshipModel {
    id: string;
    dateCreated: Date;
    email: string;
    fullName: string;
    github: string;
    interest: string;
    linkedin: string;
    phone: string;
    resumeUrl: string[];
    status: InternshipStatus;

    constructor(id = "", dateCreated = new Date(Date.now()), email = "", fullName = "", github = "", interest = "", linkedin = "", phone = "", resumeUrl = [], status: InternshipStatus = "pending") {
        this.id = id;
        this.dateCreated = dateCreated;
        this.email = email;
        this.fullName = fullName;
        this.github = github;
        this.interest = interest;
        this.linkedin = linkedin;
        this.phone = phone;
        this.resumeUrl = resumeUrl;
        this.status = status;
    }

    static fromFirestore(documentSnapshot: DocumentData): InternshipModel {
        const id = documentSnapshot.id;
        const data = documentSnapshot.data()!;
        return new InternshipModel(id, data.dateCreated.toDate(), data.email, data.fullName, data.github, data.interest, data.linkedin, data.phone, Array.isArray(data.resumeUrl) ? data.resumeUrl : [data.resumeUrl].filter(resume => !!resume), data.status);
    }

    toJSON(): Record<string, any> {
        return {
            dateCreated: Timestamp.fromDate(new Date(this.dateCreated)),
            email: this.email,
            fullName: this.fullName,
            github: this.github,
            interest: this.interest,
            linkedin: this.linkedin,
            phone: this.phone,
            resumeUrl: this.resumeUrl,
            status: this.status,
        }
    }
}
