import { DeveloperRef, DesignersDevRef } from "@/config/firebase";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";

const state = {
  loading: false,
  developers: [],
  singledeveloper: { id: " ", data: {} },
};

const getters = {
  getLoading: (state: any) => state.loading,
  getDev: (state: any) => state.developers,
  getSingledeveloper: (state: any) => state.singledeveloper,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setDev(state: any, payload: any) {
    state.developers = payload;
  },
  setSingledeveloper(state: any, payload: any) {
    state.singledeveloper = payload;
  },
};
const actions = {
  async submitDev({ commit }: any, hire: any) {
    commit("setLoading", true);
    try {
      await addDoc(DeveloperRef, Object.assign({}, hire));
      commit("setLoading", false);
      return Promise.resolve(null);
    } catch (e) {
      commit("setLoading", false);
      return Promise.reject(e);
    }
  },
  async getDev({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(DeveloperRef);
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setDev", trr_arr);
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSingleDev({ commit }: any, id: any) {
    const docRef = doc(DeveloperRef, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setSingledeveloper", { id: Snapshot.id, data: Snapshot.data() });
    }
  },

  async updateDev({ state }: any, { singledeveloper }: any) {
    const id = state.singledeveloper.id;
    try {
      const docRef = doc(DeveloperRef, id);
      await updateDoc(docRef, Object.assign({}, singledeveloper.data));
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteDev(id: any) {
    id = state.singledeveloper.id;
    try {
      const docRef = doc(DeveloperRef, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
