import { productDevRef } from "@/config/firebase";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";

const state = {
  loading: false,
  productdesigner: [],
  productdes: { id: "", data: {} },
};

const getters = {
  getLoading: (state: any) => state.loading,
  getProDev: (state: any) => state.productdesigner,
  getProductdes: (state: any) => state.productdes,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setProDev(state: any, payload: any) {
    state.productdesigner = payload;
  },
  setProductdes(state: any, payload: any) {
    state.productdes = payload;
  },
};
const actions = {
  async submitPro({ commit }: any, hirepro: any) {
    commit("setLoading", true);
    try {
      await addDoc(productDevRef, Object.assign({}, hirepro));
      commit("setLoading", false);
      return Promise.resolve(null);
    } catch (e) {
      commit("setLoading", false);
      return Promise.reject(e);
    }
  },
  async getProDev({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(productDevRef);
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setProDev", trr_arr);
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSinglePro({ commit }: any, id: any) {
    const docRef = doc(productDevRef, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setProductdes", { id: Snapshot.id, data: Snapshot.data() });
    }
  },

  async updatePro({ state }: any, { productdes }: any) {
    const id = state.productdes.id;
    try {
      const docRef = doc(productDevRef, id);
      await updateDoc(docRef, Object.assign({}, productdes.data));
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deletePro(id: any) {
    id = state.productdes.id;
    try {
      const docRef = doc(productDevRef, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
