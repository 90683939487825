import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "@/config/firebase";
import { getErrorMessage } from "@/util/firebaseErrorCodes";

const state = {
  user: {},
  loading: false,
  authIsReady: false,
};

const getters = {
  getUser: (state: any) => state.user,
  get_isLoading: (state: any) => state.loading,
  is_LoggedIn: (state: any) => state.authIsReady,
};
const mutations = {
  setUser(state: any, payload: any) {
    state.user = payload;
  },
  set_isLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setAuthIsReady(state: any, payload: any) {
    state.authIsReady = payload;
  },
};
const actions = {
  async login(context: any, { email, password }: any) {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit("setUser", response.user);
      } else return Promise.resolve(null);
    } catch (e: any) {
      return Promise.reject({
        message: e.code ? getErrorMessage(e.code) : e.message,
      });
    }
  },
  async logout(context: any) {
    try {
      await signOut(auth);
      context.commit("setUser", null);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject({
        message: error.code ? getErrorMessage(error.code) : error.message,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
