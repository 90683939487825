import {internshipCareerRef, storage} from "@/config/firebase";
import {
    addDoc,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    query,
    updateDoc,
    orderBy,
} from "firebase/firestore";
import {InternshipModel} from "@/models/internshipCareer";
import {getErrorMessage} from "@/util/firebaseErrorCodes";

const state = {
    loading: false,
    interns: [] as InternshipModel[],
    singleintern: {id: "", data: {}},
    unsubscribe: null,
};

const getters = {
    getLoading: (state: any) => state.loading,
    getInterns: (state: any) => state.interns,
    getSingleIntern: (state: any) => state.singleintern,
};
const mutations = {
    setLoading(state: any, payload: any) {
        state.loading = payload;
    },
    setInterns(state: any, payload: any) {
        state.interns = payload;
    },
    setSingleIntern(state: any, payload: any) {
        state.singleintern = payload;
    },
};
const actions = {
    async submitCareer({commit, dispatch}: any, [career, file]: [InternshipModel, File[]]) {
        commit("setLoading", true);
        try {
            career.resumeUrl = await Promise.all(file.map(resume => dispatch('utilFunctions/uploadPDFToFirebase', [resume, 'Resume'], {root: true})))
            await addDoc(internshipCareerRef, career.toJSON());
            commit("setLoading", false);
            return Promise.resolve(null);
        } catch (error: any) {
            commit("setLoading", false);
            return Promise.reject({message: error.code ? getErrorMessage(error.code) : error.message});
        }
    },
    getInterns({commit, state}: any) {
        commit('setLoading', true)
        const trr_arr: InternshipModel[] = [];
        const docRef = query(internshipCareerRef, orderBy('dateCreated', 'desc'));
        state.unsubscribe = onSnapshot(docRef, (snapshot) => {
            snapshot.forEach((doc) => {
                trr_arr.push(InternshipModel.fromFirestore(doc));
                state.interns = trr_arr;
                commit('setLoading', false)
            });
        }, error => {
            console.log(error.message);
            commit('setLoading', false)
        }
    )
        ;

    },
    async getSingleIntern({commit}: any, id: any) {
        const docRef = doc(internshipCareerRef, id);
        const Snapshot = await getDoc(docRef);
        if (Snapshot.exists()) {
            commit("setSingleIntern", {id: Snapshot.id, data: Snapshot.data()});
        }
    },

    async updateInterns({commit}: any, [singleIntern]: [InternshipModel]) {
        try {
            commit('setLoading', true)
            const docRef = doc(internshipCareerRef, singleIntern.id);
            await updateDoc(docRef, singleIntern.toJSON());
            return Promise.resolve(null);
        } catch (error: any) {
            return Promise.reject({message: error.code ? getErrorMessage(error.code) : error.message});
        } finally {
            commit('setLoading', false);
        }
    },
    async deleteInterns({dispatch}: any, singleIntern: InternshipModel) {
        try {
            if (singleIntern.resumeUrl.length > 0) {
                await Promise.all(singleIntern.resumeUrl.map(resume => dispatch('utilFunctions/deleteImageFromStorage', resume, {root: true})))
            }
            const docRef = doc(internshipCareerRef, singleIntern.id);
            await deleteDoc(docRef);

            return Promise.resolve(null);
        } catch (error: any) {
            return Promise.reject({message: error.code ? getErrorMessage(error.code) : error.message});
        }
    },
};
const modules = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
