<template>
  <div>
    <Navbar />

    <div class="containers">
      <h1 class="header-text">Grandilo Blogs</h1>
      <div class="post">
        <div class="each-post" v-for="(blog, id) in blogs" :key="id">
          <img :src="blog.displayURL" class="img" alt="post-image" />

          <div class="post-text">
            <h3>
              {{
                blog.title.length > 45
                  ? blog.title.slice(0, 45) + "..."
                  : blog.title
              }}
            </h3>
            <div class="story">{{extractPlainText(blog.body)}}</div>
            <div class="date">
              <p>
                {{ Math.ceil(blog.body.split(" ").length / 250) }} min read
              </p>
              <p>
                {{ new Date(blog.createdAt.seconds * 1000).toDateString() }}
              </p>
            </div>

            <!-- <h4>Posted by: {{ blog.createdBy }}</h4> -->
            <button class="link" @click="details(blog.id)">
              Continue reading
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import { computed, defineComponent } from "vue";
import router from "@/router";
import { store } from "@/store";
import {useFuncUtils} from "@/composables/useFuncUtils";

export default defineComponent({
  /* eslint-disable */
  name: "Blog",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const {extractPlainText} = useFuncUtils()
    store.dispatch("blog/getBlog");

    const blogs = computed(() => store.getters["blog/getBlog"]);

    function details(id: any) {
      return router.push({ name: "blog-details", params: { id } });
      
    }
    return {
      details,
      blogs,
      extractPlainText
    };
  },
});
</script>

<style scoped>
.containers {
  padding-top: 120px;
}
.header-text {
  padding: 150px auto 90px auto;
  color: black;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}
.post {
  display: flex;
  width: 88%;
  margin: auto;
  flex-wrap: wrap;
  margin-top: 40px;
}
.each-post {
  border-radius: 8px;
  padding: 10px;
  width: 20%;

  margin-right: 45px;
  margin-bottom: 70px;
  border: 1px solid rgb(209, 207, 207);
}
.story {
  font-size: 14px;
  color: rgb(101, 101, 101);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}
.story >>> img {
  display: none;
}

.each-post .img {
  width: 100%;
  height: 150px;
  margin-bottom: 30px;
  object-fit: cover;
  object-position: center;
}
.each-post .post-text {
  text-align: left;
}
.each-post h3 {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.each-post p {
  font-size: 12px;
  font-weight: 400;
  color: rgb(90, 88, 88);
}
.each-post h4 {
  font-size: 16px;
  padding-bottom: 30px;
}
.link {
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
  width: 50%;
  outline: none;
  border: 1px solid rgb(216, 214, 214);
  color: rgb(59, 57, 57);
}
.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}
.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}
.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}
.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}
.date {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 807px) {
  .each-post {
    width: 98%;
    margin: auto;
    margin-bottom: 70px;
  }
}
</style>
