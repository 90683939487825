import { DesignersDevRef } from "@/config/firebase";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";

const state = {
  loading: false,
  designers: [],
  singledesigners: { id: " ", data: {} },
};

const getters = {
  getLoading: (state: any) => state.loading,
  getDes: (state: any) => state.designers,
  getSingledesigners: (state: any) => state.singledesigners,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setDes(state: any, payload: any) {
    state.designers = payload;
  },
  setSingledesigners(state: any, payload: any) {
    state.singledesigners = payload;
  },
};
const actions = {
  async submitDes({ commit }: any, result: any) {
    commit("setLoading", true);
    try {
      await addDoc(DesignersDevRef, Object.assign({}, result));
      commit("setLoading", false);
      return Promise.resolve(null);
    } catch (e) {
      commit("setLoading", false);
      return Promise.reject(e);
    }
  },
  async getDes({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(DesignersDevRef);
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setDes", trr_arr);
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getSingledesigners({ commit }: any, id: any) {
    const docRef = doc(DesignersDevRef, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setSingledesigners", { id: Snapshot.id, data: Snapshot.data() });
    }
  },

  async updateDes({ state }: any, { singledesigners }: any) {
    const id = state.singledesigners.id;
    try {
      const docRef = doc(DesignersDevRef, id);
      await updateDoc(docRef, Object.assign({}, singledesigners.data));
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteDes(id: any) {
    id = state.singledesigners.id;
    try {
      const docRef = doc(DesignersDevRef, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
