import { FieldValue, serverTimestamp } from "firebase/firestore";
export class hireModel{
    email: string;
    phone_num: string;
    areas: [];
    message: string;
    company_name: string;
    full_name: string;
    roles: string;
    experience: [];
    dateCreated: FieldValue;

    constructor(){
        this.email= ""
        this.phone_num= ""
        this.areas= []
        this.message= ""
        this.company_name= ""
        this.full_name= ""
        this.roles= ""
        this.experience= []
        this.dateCreated= serverTimestamp()
    }
}