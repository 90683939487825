import { DocumentData, Timestamp } from "firebase/firestore";

export class WorkWithUsModel {
  id: string;
  country: string;
  email: string;
  phone: string;
  isNewProject: boolean;
  haveFunctionalDocument: boolean;
  currentFunctionalDocument: string;
  services: string[];
  remark: string;
  companyName: string;
  fullName: string;
  aboutUs: string;
  projectDescription: string;
  dateCreated: Date;
  dateUpdated: Date;
  status: string;

  constructor(
    id = "",
    country = "",
    email = "",
    phone = "",
    isNewProject = false,
    haveFunctionalDocument = false,
    currentFunctionalDocument = "",
    services = [],
    remark = "",
    companyName = "",
    fullName = "",
    aboutUs = "",
    projectDescription = "",
    dateCreated = new Date(Date.now()),
    dateUpdated = new Date(Date.now()),
    status = "Pending" // default status
  ) {
    this.id = id;
    this.country = country;
    this.email = email;
    this.phone = phone;
    this.isNewProject = isNewProject;
    this.haveFunctionalDocument = haveFunctionalDocument;
    this.currentFunctionalDocument = currentFunctionalDocument;
    this.services = services;
    this.remark = remark;
    this.companyName = companyName;
    this.fullName = fullName;
    this.aboutUs = aboutUs;
    this.projectDescription = projectDescription;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
    this.status = status;
  }

  static toFirestore(application: DocumentData): WorkWithUsModel {
    const id = application.id;
    const data = application.data()!;
    return new WorkWithUsModel(
      id,
      data.country,
      data.email,
      data.phone,
      data.isNewProject,
      data.haveFunctionalDocument,
      data.currentFunctionalDocument,
      data.services,
      data.remark,
      data.companyName,
      data.fullName,
      data.aboutUs,
      data.projectDescription,
      data.dateCreated.toDate(),
      data.dateUpdated.toDate(),
      data.status
    );
  }

  toJSON(): Record<string, any> {
    return {
      country: this.country,
      email: this.email,
      phone: this.phone,
      isNewProject: this.isNewProject,
      haveFunctionalDocument: this.haveFunctionalDocument,
      currentFunctionalDocument: this.currentFunctionalDocument,
      services: this.services,
      remark: this.remark,
      companyName: this.companyName,
      fullName: this.fullName,
      aboutUs: this.aboutUs,
      projectDescription: this.projectDescription,
      dateCreated: Timestamp.fromDate(new Date(this.dateCreated)),
      dateUpdated: Timestamp.fromDate(new Date(this.dateUpdated)),
      status: this.status,
    };
  }
}
