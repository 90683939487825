<template>
  <div>
    <div class="footer" id="contact">
      <div class="row mb-5 footer-content1">
        <div class="col-md-3 footer-right">
          <router-link to="/">
            <img
                class="mb-3"
                src="../assets/images/logo.png"
                alt="logo"
                width="150"/></router-link
          >
          <br/>
          <div class="text">
            <p>
              Plot 8 The Providence St, Lekki Phase 1, Lekki 106104, Lagos
            </p>
          </div>
        </div>
        <div class="col-md-3 footer-content">
          <h2>About Us</h2>

          <p>
            Grandilo is an IT company that provide Mobile and Web solution for
            businesses across the world
          </p>

          <p>Showcase</p>
        </div>

        <div class="col-md-3 footer-content">
          <h2>OUR SERVICES</h2>
          <ul>
            <li>
              <router-link to="#">Web Design</router-link>
            </li>
            <li>
              <router-link to="#">Mobile App Development</router-link>
            </li>
            <li>
              <router-link to="#">Training</router-link>
            </li>
            <li>
              <router-link class="terms" to="#">Consultancy</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3 footer-content">
          <h2>CONTACT US</h2>
          <ul class="ul">
            <li>
              <i class="fa fa-envelope" style="color: white"></i>
              info@grandilo.com
            </li>
            <li>
              <i class="fa fa-phone" style="color: white"></i>
              <a href="tel: +234 8111875720"> +234 8111875720</a>
            </li>
            <li>
              <i class="fa fa-whatsapp" style="color: #218d80"></i> +234
              8111875720
            </li>
            <li class="last">
              <a href="https://twitter.com/grandilong" target="_blank"> <img src="../assets/icons/download2.svg"/></a>
              <a href="https://ng.linkedin.com/company/grandilo" target="_blank"> <img
                  src="../assets/icons/download3.svg"/></a>
              <a href="https://web.facebook.com/grandilo" target="_blank"> <img
                  src="../assets/icons/download1.svg"/></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <p class="p-header">
          &copy; {{ (() => new Date().getFullYear())() }} Grandilo Technology.
        </p>
        <p class="bottom-p">
          Grandilo Tehnology is Registered with the Corperate Affairs Commission
          with Reg number: BN2424789
        </p>
      </div>
    </div>
    <button class="backtotop" v-show="top" @click.prevent="BacktoTop('#top')">
      <i className="fa fa-arrow-up text-white"></i>
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
  /* eslint-disable */
  name: "Footer",
  setup() {
    const top = ref(false);
    onMounted(() => {
      window.addEventListener("scroll", handleScrolltop);
    });

    function BacktoTop(content: string) {
      // Scroll to a certain element
      document.querySelector(content)?.scrollIntoView({
        behavior: "smooth",
      });
    }

    function handleScrolltop() {
      if (window.scrollY > 100) {
        top.value = true;
      } else {
        top.value = false;
      }
    }

    return {
      top,
      BacktoTop,
    };
  },
});
</script>

<style scoped>
.backtotop {
  background-color: #009688;
  color: white;
  width: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: -100px;
  z-index: 4;
  padding: 5px;
  border-radius: 50px;
  box-shadow: 2px 2px 4px rgba(88, 133, 73, 0.986);
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  transition: 0.5s;
}

.backtotop:hover {
  margin-bottom: 5px;
}

.footer {
  background-color: black;
  position: relative;
  height: 50%;
  width: 100%;
  padding: 30px;
  padding-top: 70px;
}

.footer-content1 {
  width: 90%;
  margin: auto;
}

.footer-content h2 {
  color: #ffff;
  font-family: sans-serif;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: left;
}

.footer-content p {
  text-align: left;
  width: 80%;
  font-size: 14px;
  color: #ffff;
}

.footer-content ul {
  list-style: none;
  text-align: left;
  margin-left: -40px;
}

.footer-content .ul {
  list-style: none;
  text-align: left;
  margin-left: -40px;
}

.footer-content ul li {
  color: #ffff;
  font-size: 14px;
}

.last {
  margin-left: 20px;
  padding-top: 10px;
}

.last img {
  padding: 4px;
}

.footer-content a {
  color: #ffff;
  padding: 0;
  text-decoration: none;
}

.apple img:hover {
  border: 2px solid #218d80;
  border-radius: 4px;
}

.footer-bottom {
  background-color: black;
  text-align: center;
  color: #ffff;
  padding: 10px;
}

.footer-bottom p {
  font-size: 14px;
  color: #fff;
}

.footer-right {
  text-align: left;
}

.footer-right .text p {
  color: #ffff;
  width: 70%;
  font-size: 13px;
}

.terms1 {
  display: none;
}

.bottom-p {
  width: 40%;
  margin: auto;
}

@media screen and (max-width: 807px) {
  .bottom-p {
    width: 80%;
    margin: auto;
  }

  .footer {
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer-content .ul {
    list-style: none;
    text-align: left;
    margin-left: -40px;
  }

  .footer-content ul {
    margin-left: -40px;
  }

  .footer-content .ul .last {
    margin-left: -2px;
  }

  .footer-bottom .p-header {
    color: #218d80;
    margin-top: -40px;
    padding-bottom: 30px;
  }
}
</style>
