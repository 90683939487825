<template>
  <div>
    <Navbar/>
    <div class="contact">
      <div class="contact-text">
        <h1>Contact Us</h1>
        <p>We usually reply within two business days</p>
      </div>
    </div>
    <h1 class="form-text">Contact Form</h1>
    <div class="form">
      <form class="contact-form" @submit.prevent="contact_us">
        <div class="form-item">
          <label for="fullname">Full Name</label>
          <input
              type="text"
              v-model="contact.fullname"
              placeholder="Enter Full Name"
              id="fullname"
          />
          <span
              v-for="error in v$.fullname.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="email">Email</label>
          <input
              type="email"
              v-model="contact.email"
              placeholder="Enter your email"
              id="email"
          />
          <span
              v-for="error in v$.email.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="phone">Phone Number</label>
          <input
              type="tel"
              v-model="contact.phone"
              placeholder="Enter Phone Number"
              id="phone"
          />
          <span
              v-for="error in v$.phone.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="topic">Topic</label>
          <input
              type="text"
              v-model="contact.topic"
              placeholder="Enter a Subject"
              id="topic"
          />
          <span
              v-for="error in v$.topic.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="message">Message</label>
          <textarea
              name="message"
              v-model="contact.message"
              id="message"
              placeholder="Enter your message"
          ></textarea>
          <span
              v-for="error in v$.message.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <button class="mt-3" type="submit">{{ loader }}</button>
      </form>
    </div>

    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer/>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, reactive, toRefs} from "vue";
import Footer from "./Footer.vue";
import Navbar from "./Navbar.vue";
import {contactModel} from "@/models/contact";
import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import {store} from "@/store";
import router from "@/router";
import Util from "@/util/Util";

export default defineComponent({
  /* eslint-disable */
  name: "ContactUs",
  components: {
    Footer,
    Navbar,
  },
  setup() {
    const state = reactive({
      contact: new contactModel(),
      loader: "Submit Request",
    });

    const rules = computed(() => {
      return {
        email: {required, email},
        phone: {required},
        topic: {required},
        message: {required},
        fullname: {required},
      };
    });
    const loading = computed(() => store.getters["contact/getLoading"]);

    const v$ = useVuelidate(rules, state.contact);

    async function contact_us() {
      const result = await v$.value.$validate();
      if (!result) {
        return false;
      } else {
        try {
          state.loader = "Submitting.....";
          await store.dispatch("contact/submitContact", state.contact);
          // state.contact = new contactModel();
          state.loader = "Submit Request";
          await router.push({path: "/thankyou"});
        } catch (err: any) {
          state.loader = "Submit Request";
          Util.sweetAlert2("Error Encountered", err.message, "error", 4000);
        }
      }
    }

    return {
      rules,
      v$,
      loading,
      ...toRefs(state),
      contact_us,
    };
  },
});
</script>

<style scoped>
.contact {
  background: linear-gradient(
      to bottom,
      rgba(11, 71, 65, 0.45),
      rgba(11, 71, 65, 0.45)
  ),
  url(../assets/images/joyce-busola-L9U5UUScnHY-unsplash.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
  width: 100%;
  margin-bottom: 30px;
  color: #ffff;
}

.contact .contact-text {
  margin: auto;
  width: 70%;
  padding-top: 200px;
  text-align: left;
  color: #fbfbfb;
}

.contact-text h1 {
  font-style: normal;
  text-transform: uppercase;
  color: #fbfbfb;
  font-weight: 700;
  font-size: 72px;
}

.contact-text p {
  letter-spacing: 1px;
  font-size: 20px;
  margin-top: -12px;
}

.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}

.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}

.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}

.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}

.contact-form {
  width: 55%;
  margin: auto;
  margin-bottom: 100px;
  height: auto;
  padding: 40px;
  border-radius: 3px;
}

.contact-form .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-item label {
  text-align: left;
  font-size: 14px;
  color: black;
  font-family: sans-serif;
  font-weight: 600;
}

.form-item input,
.form-item textarea {
  border-radius: 6px;
  margin-top: -5px;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  border: none;
  font-size: 14px;
  background-color: #ececec;
}

.form-text {
  color: black;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.contact-form button {
  width: 150px;
  float: left;
  text-align: center;
  padding: 8px;
  font-weight: 600;
  outline: none;
  background-color: #009688;
  color: #fff;
  border: 1px solid #009688;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.contact-form button:hover {
  background: #ffffff;
  color: #009688;
}

.error {
  color: rgb(201, 6, 6);
  font-size: 12px;
  text-align: left;
}

@media screen and (max-width: 807px) {
  .contact {
    height: 326px;
  }

  .contact-form {
    width: 100%;
    padding: 20px;
  }

  .contact-form button {
    float: none;
  }

  .contact .contact-text {
    padding-top: 150px;
    width: 100%;
    text-align: center;
  }

  .contact-text h1 {
    font-weight: 700;
    font-size: 20px;
  }

  .form-text {
    font-size: 17px;
  }

  .contact-text p {
    font-size: 14px;
    margin-top: 12px;
  }

  .bottom span:first-child {
    height: 350px;
    margin-right: 45px;
    margin-top: -40px;
    border-top-right-radius: 20px;
  }

  .form-text {
    margin-top: 50px;
  }

  .bottom span:last-child {
    height: 250px;
    margin-right: 10px;
    border-top-right-radius: 20px;
  }

  .contact {
    background-size: cover;
  }

  .bottom {
    display: none;
  }
}
</style>
