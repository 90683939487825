import { bookingRef, db } from "@/config/firebase";
import { bookingModel } from "@/models/booking";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";

const state = {
  loading: false,
  booking: [],
  book: { id: "", data: {} },
};

const getters = {
  getLoading: (state: any) => state.loading,
  getBooking: (state: any) => state.booking,
  getBook: (state: any) => state.book,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setBooking(state: any, payload: any) {
    state.booking = payload;
  },
  setBook(state: any, payload: any) {
    state.book = payload;
  },
};
const actions = {
  async submitBooking({ commit }: any, booking: any) {
    commit("setLoading", true);
    try {
      await addDoc(bookingRef, Object.assign({}, booking));
      commit("setLoading", false);
      return Promise.resolve(null);
    } catch (e) {
      commit("setLoading", false);
      return Promise.reject(e);
    }
  },
  async getBooking({ commit }: any) {
    try {
      const trr_arr: any[] = [];
      const docRef = query(bookingRef);
      const snapshot = onSnapshot(docRef, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          trr_arr.push(data);
          commit("setBooking", trr_arr);
        });
      });
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getBook({ commit }: any, id: any) {
    const docRef = doc(bookingRef, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setBook", { id: Snapshot.id, data: Snapshot.data() });
    }
  },

  async updateBooking({ state }: any, { book }: any) {
    const id = state.book.id;
    try {
      const docRef = doc(bookingRef, id);
      await updateDoc(docRef, Object.assign({}, book.data));
      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async deleteBookings(id: any) {
    id = state.book.id;
    try {
      const docRef = doc(bookingRef, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
