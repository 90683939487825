import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import About from "../components/About.vue";
import Career from "../components/InternshipCareer.vue";
import ContactUs from "../components/ContactUs.vue";
import Book from "../components/Book.vue";
import Hire from "../components/Hire.vue";
import Blog from "../components/Blog.vue";
import Blog_Details from "../components/Blog_Details.vue";
import WorkWithUs from "../components/WorkWithUs.vue";
import { auth } from "@/config/firebase";
import ThankYou from "../components/ThankYou.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contactus",
    name: "contactus",
    component: ContactUs,
  },
  {
    path: "/career",
    name: "career",
    component: Career,
  },
  {
    path: "/book",
    name: "book",
    component: Book,
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: ThankYou,
  },
  {
    path: "/hire",
    name: "hire",
    component: Hire,
  },
  {
    path: "/blog-post",
    name: "blog-post",
    component: Blog,
  },
  {
    path: "/blog-details/:id",
    name: "blog-details",
    component: Blog_Details,
  },
  {
    path: "/workwithus",
    name: "workwithus",
    component: WorkWithUs,
  },
  {
    path: "/admin",
    component: () => import("../components/dashboard/Container.vue"),
    children: [
      {
        name: "login",
        path: "",
        component: () => import("../components/dashboard/Login.vue"),
        meta: {
          requiresVisitor: true,
        },
      },
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("../components/dashboard/Dashboard1.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "application",
        path: "application",
        component: () =>
          import("../components/dashboard/Application/Application.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "meeting",
        path: "meeting",
        component: () => import("../components/dashboard/Meeting.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "developersapp",
        path: "developersapp",
        component: () =>
          import("../components/dashboard/Developer/DevelopersApp.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "productdesignersapp",
        path: "productdesignersapp",
        component: () =>
          import(
            "../components/dashboard/ProductDesigner/ProductDesignersApp.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "designersapp",
        path: "designersapp",
        component: () =>
          import("../components/dashboard/Designer/DesignersApp.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "contact",
        path: "contact",
        component: () => import("../components/dashboard/Contact/Contact.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "bookings",
        path: "bookings",
        component: () => import("../components/dashboard/Booking/Bookings.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "staff",
        path: "staff",
        component: () => import("../components/dashboard/Stafflog.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "blog",
        path: "blog",
        component: () => import("@/components/dashboard/blog/Blogs.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "internship",
        path: "internship",
        component: () =>
          import("@/components/dashboard/Internship/Internship.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach(async (to, from, next) => {
  const loggedUser = await new Promise((resolve) =>
    auth.onAuthStateChanged((user) => {
      resolve(user);
    })
  );
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !loggedUser) {
    return next({ name: "login" });
  } else if (
    to.matched.some((record) => record.meta.requiresVisitor && loggedUser)
  ) {
    next({
      name: "dashboard",
    });
  } else {
    next();
  }
});

export default router;
