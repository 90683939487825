<template>
  <Navbar />
  <div class="home">
    <div class="header row">
      <div class="header-text col-md-6 col-sm-12">
        <span>
          <h1>
            Finding it difficult to <strong style="color: red">scale?</strong>
          </h1>
          <p>Let’s help your scale from 0 users to your first 1M users</p>
        </span>
        <span>
          <h1>
            Need a <strong style="color: red">Technical</strong> partner in your
            team?
          </h1>
          <p>
            Do have all the business prowess and strategy but lack a Technical
            partner. Let's get you a technical partner on the go
          </p>
        </span>
        <span>
          <h1>
            Take your <strong style="color: red">idea</strong> from paper to
            market
          </h1>
          <p>
            We have a tested product development framework that has helped over
            50 customers to test the market fit of their idea
          </p>
        </span>
        <span>
          <h1>
            Build your product <strong style="color: red">MVP</strong> in weeks
          </h1>
          <p>
            With over 9 years of experience in the tech industry, we have built
            over 100 MVPS for businesses around the world
          </p>
        </span>
        <div class="buttons">
          <router-link to="/workwithus">Get started</router-link>
        </div>
      </div>
    </div>
    <div class="header-img"></div>
    <div class="trusted">
      <h3>Trusted By</h3>
      <swiper
        :slidesPerView="6"
        :spaceBetween="-30"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :effect="'coverflow'"
        :grabCursor="true"
        :modules="modules"
        :breakpoints="{
          200: {
            slidesPerView: 3,
            spaceBetween: -25,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: -25,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: -25,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 4,
          },
        }"
        class="trusted-item"
      >
        <swiper-slide>
          <img
            src="../assets/images/google-logo.svg"
            width="100"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/Vector1.svg"
            width="100"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/Vector2.svg"
            width="100"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/Vector3.svg"
            width="100"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/Vector4.svg"
            width="100"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/fame_star.png"
            width="100"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/have_my_room.png"
            width="160"
            height="100"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="../assets/images/ilink.png"
            width="200"
            height="50"
            alt=""
          />
        </swiper-slide>
      </swiper>
    </div>

    <div class="what-we-do">
      <h3 class="title">What We Do</h3>
      <div class="item-list">
        <div class="items">
          <img src="../assets/icons/laptop.png" alt="" width="70" height="70" />
          <h5>
            <strong>Website Application</strong>
          </h5>
          <p>
            Ranging from basic websites to dynamic web aplications for
            automation of your daily business/office activities.
          </p>
          <div>
            <router-link to="/workwithus">Start a Project</router-link>
          </div>
        </div>
        <div class="items">
          <img
            class="img1"
            src="../assets/icons/Vector2.svg"
            alt=""
            width="60"
            height="70"
          />
          <img
            class="img2"
            src="../assets/images/Vector11.svg"
            alt=""
            width="60"
            height="70"
          />
          <h5>
            <strong>Mobile App Development</strong>
          </h5>
          <p>
            Develop mobile applications that are amazing, enhancing and
            simplifies your life; both for Android and iOS platform.
          </p>
          <div>
            <router-link to="/workwithus">Start a Project</router-link>
          </div>
        </div>
        <div class="items">
          <img src="../assets/icons/Group.png" alt="" width="70" height="70" />
          <h5>
            <strong>Technical Consultancy</strong>
          </h5>
          <p>
            We offer expert IT advice that will optimize and scale your existing
            tech infrastructure.
          </p>
          <div>
            <router-link to="/workwithus">Start a Project</router-link>
          </div>
        </div>
        <div class="items">
          <img
            src="../assets/icons/education.png"
            alt=""
            width="70"
            height="70"
          />
          <h5>
            <strong>Corporate Training</strong>
          </h5>
          <p>
            We organize in house IT workshop for businesses to help improve
            their staff productivity.
          </p>
          <div>
            <router-link to="/workwithus">Start a Project</router-link>
          </div>
        </div>
        <div class="items">
          <img
            class="img1"
            src="../assets/icons/Group1.png"
            alt=""
            width="70"
            height="70"
          />
          <img
            class="img2"
            src="../assets/icons/Group.svg"
            alt=""
            width="70"
            height="70"
          />
          <h5>
            <strong>Search Engine Optimization</strong>
          </h5>
          <p>Helping your website to rank top in search results.</p>
          <div>
            <router-link to="/workwithus">Start a Project</router-link>
          </div>
        </div>
        <div class="items">
          <img src="../assets/icons/pie.png" alt="" width="70" height="70" />
          <h5>
            <strong>Project Management</strong>
          </h5>
          <p>
            We see organizations through the initiation, planning, design,
            execution, monitoring, controlling and closure of a project.
          </p>
          <div>
            <router-link to="/workwithus">Start a Project</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="flows-lg">
      <h2 class="title">Our Work Flow</h2>
      <swiper
        :slidesPerView="4"
        :spaceBetween="20"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :effect="'coverflow'"
        :grabCursor="true"
        :modules="modules"
        :breakpoints="{
          200: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3.5,
          },
          1024: {
            slidesPerView: 3.5,
          },
        }"
        class="flow-list"
      >
        <swiper-slide class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_co-workers_ujs6.svg"
          />
          <h5>Project Briefing</h5>
          <p>
            When we receive your business requirement document, we study and
            scope it
          </p>
        </swiper-slide>
        <swiper-slide class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_customer_survey_f9ur.svg"
          />
          <h5>Technical Analysis</h5>
          <p>We analyse the project scope, draw a plan and kick-off.</p>
        </swiper-slide>
        <swiper-slide class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_design_tools_42tf.svg"
          />
          <h5>Design</h5>
          <p>This is where the magic happens.</p>
        </swiper-slide>
        <swiper-slide class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_programming_re_kg9v.svg"
          />
          <h5>Development</h5>
          <p>Implementation of designs with code</p>
        </swiper-slide>
        <swiper-slide class="flow">
          <img
            class="imgicon"
            src="../assets/icons/market_launch-cuate.png"
            style="width: 250px"
          />
          <h5>Go Live</h5>
          <p>Here we celebrate as we launch that amazing product</p>
        </swiper-slide>
      </swiper>
    </div>

    <div class="flows-sm">
      <h2 class="title">Our Work Flow</h2>
      <div class="flow-list">
        <div class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_co-workers_ujs6.svg"
          />
          <h5>Project Briefing</h5>
          <p>
            When we receive your business requirement document, we study and
            scope it
          </p>
        </div>
        <div class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_customer_survey_f9ur.svg"
          />
          <h5>Technical Analysis</h5>
          <p>We analyse the project scope, draw a plan and kick-off.</p>
        </div>
        <div class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_design_tools_42tf.svg"
          />
          <h5>Design</h5>
          <p>This is where the magic happens.</p>
        </div>
        <div class="flow">
          <img
            class="imgicon"
            src="../assets/icons/undraw_programming_re_kg9v.svg"
          />
          <h5>Development</h5>
          <p>Implementation of designs with code</p>
        </div>
        <div class="flow">
          <img
            class="imgicon"
            src="../assets/icons/market_launch-cuate.png"
            style="width: 250px"
          />
          <h5>Go Live</h5>
          <p>Here we celebrate as we launch that amazing product</p>
        </div>
      </div>
    </div>

    <div class="hire">
      <div class="hire-text col-md-5 col-sm-12">
        <h1>
          Recruit <span style="color: red">world class</span> developers with
          less hassle.
        </h1>
        <p>
          We have a team of well vetted developers ready to be plugged in to
          your team as full time or part time staff.
        </p>
        <div class="btn12">
          <router-link to="/hire"> Hire Now</router-link>
        </div>
      </div>
      <div class="hire-img col-md-6 col-sm-12"></div>
    </div>
    <div class="book">
      <div class="book-img col-md-5 col-sm-12"></div>
      <div class="book-text col-md-5 col-sm-12">
        <h1>Advisory sessions</h1>
        <p>
          We run mentorship/advisory sessions for companies, startups and
          individuals providing outstanding insights.
        </p>
        <div class="btn12">
          <router-link to="/book">Book a Session</router-link>
        </div>
      </div>
      <div class="book-img2 col-md-12 col-sm-12"></div>
    </div>

    <div>
      <div>
        <h2 class="title">Testimonials</h2>
        <p style="margin-top: -20px; text-align: center">
          What <span style="color: red">clients</span> have had to say
        </p>
      </div>
      <swiper
        :slidesPerView="2"
        :spaceBetween="20"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :effect="'coverflow'"
        :grabCursor="true"
        :modules="modules"
        :breakpoints="{
          200: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        }"
        class="testimony"
      >
        <swiper-slide class="testimony-item" style="background-color: #72351c">
          <div class="testimony-item-text">
            <h2>Mr Gaius Ebere</h2>
            <p>Google Apps Administrator. University of Nigeria</p>
            <h3>
              <span class="text-dark">“</span>You guys have beaten my
              expectation<span class="text-dark">”</span>
            </h3>
          </div>
          <div class="img-item1"></div>
        </swiper-slide>
        <swiper-slide class="testimony-item" style="background-color: #67647e">
          <div class="testimony-item-text">
            <h2>Mrs Adesogan Nkiru</h2>
            <p>President, Le Niveau Consult</p>
            <h3>
              <span class="text-dark">“</span>I love it when I see young guys
              disrupting the way we do things. Keep it up Grandilo<span
                class="text-dark"
                >”</span
              >
            </h3>
          </div>
          <div class="img-item2"></div>
        </swiper-slide>
        <swiper-slide class="testimony-item" style="background-color: #67647e">
          <div class="testimony-item-text">
            <h2>Dr. Goodhead Uchendu</h2>
            <p>Doctor of Vetinary Medicine</p>
            <h3>
              <span class="text-dark">“</span>While in Botwana, I've been haring
              about your hand work. I cant afford loosing the relationship we
              have<span class="text-dark">”</span>
            </h3>
          </div>
          <div class="img-item3"></div>
        </swiper-slide>
      </swiper>
    </div>

    <div>
      <div style="padding-bottom: 50px">
        <h2 class="title">Blog Posts</h2>
        <p style="margin-top: -20px; text-align: center">
          Collection of our latest blog posts about
          <span style="color: red"
            >tech & software engineering trends, startup advice,</span
          >
          and business insights
        </p>
      </div>

      <div class="post">
        <div class="each-post1" v-for="(blog, id) in blogs" :key="id">
          <div
            class="img"
            :style="{ backgroundImage: `url(${blog.displayURL})` }"
          ></div>
          <!-- <img :src="blog.displayURL" class="img" alt="post-image" /> -->
          <div class="post-text">
            <h3 style="font-weight: bold">
              {{
                blog.title.length > 40
                  ? blog.title.slice(0, 40) + "..."
                  : blog.title
              }}
            </h3>
            <div
              class="story" >{{extractPlainText(blog.body)}}</div>
            <div class="date">
              <p>{{ Math.ceil(blog.body.split(" ").length / 250) }} min read</p>
              <p>
                {{ new Date(blog.createdAt.seconds * 1000).toDateString() }}
              </p>
            </div>

            <button class="link" @click="details(blog.id)">
              Continue reading
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import router from "@/router";
import { store } from "@/store";
import {useFuncUtils} from "@/composables/useFuncUtils";
export default defineComponent({
  /* eslint-disable */
  name: "Home",
  components: {
    Navbar,
    Footer,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const {extractPlainText} = useFuncUtils();
    store.dispatch("blog/getFiveBlogs");

    const blogs = computed(() => store.getters["blog/getFiveBlog"]);

    function details(id: any) {
      return router.push({ name: "blog-details", params: { id } });
      // console.log(id);
    }
    return {
      details,
      modules: [Autoplay, Pagination, Navigation],
      blogs,
      extractPlainText
    };
  },
});
</script>

<style scoped>
.header {
  background-image: linear-gradient(
      to bottom,
      rgba(11, 71, 65, 0.45),
      rgba(11, 71, 65, 0.45)
    ),
    url(../assets/images/hero_image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: auto;
  height: 100vh;
  position: relative;
}
.header-text {
  margin: auto;
  margin-left: 55px;
  text-align: left;
}
.header-text span {
  color: #ffffff;
  margin-top: -140px;
  position: absolute;
  animation: remoteFadeInOut 40s linear infinite 0s;
  opacity: 0;
}

.header-text p {
  width: 68%;
  font-weight: 300;
  font-size: 20px;
}
.header-text h1 {
  width: 100%;
  font-weight: 700;
  font-size: 60px;
}
.header-text span:nth-child(2) {
  animation-delay: 10s;
}
.header-text span:nth-child(3) {
  animation-delay: 20s;
}
.header-text span:nth-child(4) {
  animation-delay: 30s;
}
@keyframes remoteFadeInOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.buttons {
  position: absolute;
  margin-top: 150px;
}
.header-text .buttons a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 17px 35px 17px 35px;
  text-align: center;
  border: 1px solid #199a8b;
  color: #ffffff;
  background: #199a8b;
  border-radius: 5px;
}
.header-text a:hover {
  background-color: #fff;
  color: #199a8b;
}
.header-img {
  height: 80vh;
  margin: auto;
  margin-left: 1px;
  width: 100%;
  display: none;
  background-image: url(../assets/images/hero_image.png);
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.trusted img {
  width: 100px;
  height: 100px;
}
.trusted {
  background: rgb(8, 61, 55);
  height: 172px;
  margin: none;
  color: #ffff;
}
.trusted-item {
  width: 95%;
  margin: auto;
}
.trusted h3 {
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  text-align: center;
}

.title {
  margin: 90px 0 20px 0;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}
.item-list {
  display: flex;
  justify-content: center;
  width: 93%;
  margin: auto;
  flex-wrap: wrap;
}
.item-list .items {
  width: 30%;
  padding: 20px;
  height: 283px;
  margin: 15px;
  text-align: center;
  color: #000000;
  border-radius: 3px;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: rgba(208, 208, 208, 0.4);
  position: relative;
  z-index: 1;
  transition: color 0.5s;
}
.item-list .items img {
  padding: 10px;
}
.img1 {
  display: block;
  margin: auto;
}
.img2 {
  display: none;
}
.item-list .items div {
  padding-top: 90px;
}
.item-list .items div a {
  display: none;
  width: 150px;
  border-radius: 5px;
  padding: 7px;
  z-index: 2;
  text-decoration: none;
  background-color: #199a8b;
  color: #fff;
}
.item-list .items:hover div a {
  display: block;
  margin: auto;
  text-align: center;
}
.item-list .items div {
  width: 0;
  position: absolute;
  background: rgba(53, 52, 52, 0.78);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.5s;
}
.item-list .items:hover div {
  width: 100%;
}
.item-list .items h5 {
  font-style: normal;
  padding-top: 10px;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
}
.item-list .items p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
}
.item-list .items:nth-child(2),
.item-list .items:nth-child(5) {
  color: #ffff;
  background: rgba(10, 73, 66, 0.9);
}
.imgicon {
  width: 150px;
  height: 150px;
}
.flow-list {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  width: 100%;
  flex-wrap: nowrap;
}
.flows-sm {
  display: none;
}
.flow {
  width: 23%;
  margin: 30px 2px;
}
.flow h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}
.flow p {
  font-style: normal;
  font-weight: 300;
  width: 80%;
  margin: auto;
  font-size: 20px;
  text-align: center;
}
.flow img {
  width: 180px;
}
.hire {
  width: 100%;
  display: flex;
  margin-top: 30px;
}
.hire-text {
  margin: auto;
  margin-left: 70px;
  text-align: left;
}
.hire-text p {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  width: 90%;
}
.hire-text h1 {
  width: 90%;
  font-weight: 700;
  font-size: 44px;
}
.hire-text a {
  font-weight: 600;
  font-size: 14px;
  width: 200px;
  height: 48px;
  text-decoration: none;
  padding: 16px 57px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #199a8b;
  background-color: #199a8b;
  border-radius: 5px;
}
.hire-text a:hover {
  background-color: #ffff;
  color: #199a8b;
}
.hire-img {
  height: 400px;
  margin: auto;
  background-image: url(../assets/images/christina1.svg);
  border-radius: 1px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.book {
  width: 100%;
  display: flex;
  margin-top: 80px;
}
.book-text {
  margin: auto;
  margin-left: 50px;
  text-align: left;
}
.book-text p {
  text-align: left;
  font-weight: 300;
  font-size: 24px;
}
.book-text h1 {
  font-weight: 700;
  font-size: 44px;
}
.book-text a {
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  width: 162px;
  height: 48px;
  left: 100px;
  padding: 16px 45px;
  text-align: center;
  border: 1px solid #199a8b;
  background-color: #ffffff;
  color: #199a8b;
  border-radius: 5px;
}
.book-text a:hover {
  color: #fff;
  background-color: #199a8b;
}
.book-img {
  height: 400px;
  margin: auto;
  margin-left: 70px;
  background-image: url(../assets/images/christina.svg);
  border-radius: 1px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.book-img2 {
  height: 400px;
  margin: auto;
  display: none;
  margin-left: 70px;
  background-image: url(../assets/images/christina.svg);
  border-radius: 1px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.testimony {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;
  margin-bottom: 40px;
  width: 90%;
  margin: auto;
}
.testimony-item {
  width: 40%;
  color: #fff;
  display: flex;
  height: 260px;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 10px;
}
.testimony-item-text {
  padding: 25px 15px 25px 15px;
  width: 55%;
  text-align: left;
}
.testimony-item-text h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.testimony-item-text p {
  font-style: normal;
  font-weight: 300;
  color: rgb(202, 200, 200);
  margin-top: -10px;
  font-size: 12px;
}
.testimony-item-text h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
}
.testimony-item .img-item2 {
  background-image: url(../assets/images/nk-min.svg);
  background-position: center;
  background-size: cover;
  width: 45%;
  background-repeat: no-repeat;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.testimony-item .img-item1 {
  background-image: url(../assets/images/gaius-min.png);
  background-position: center;
  background-size: cover;
  width: 45%;
  background-repeat: no-repeat;
  background-color: rgb(199, 194, 194);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.testimony-item .img-item3 {
  background-image: url(../assets/images/goodhead.png);
  background-position: center;
  background-size: cover;
  width: 45%;
  background-repeat: no-repeat;
  background-color: gray;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}
.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}
.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}
.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}
.post {
  width: 90%;
  margin: auto;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.each-post1 {
  display: flex;
  border-radius: 4px;
  height: 200px;
  width: 40%;
  margin-right: 20px;
  margin-bottom: 70px;
  border: 1px solid rgb(209, 207, 207);
}
.each-post1 .img {
  width: 40%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.each-post1 .post-text {
  text-align: left;
  width: 60%;
  padding: 12px;
}
.each-post1 h3:hover,
h4:hover,
p:hover,
span:hover {
  color: #199a8b;
  cursor: pointer;
}
.each-post1 h3 {
  text-align: left;
  font-size: 15px;
}
.each-post1 span {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}
.each-post1 h4 {
  font-size: 16px;
}
.each-post1 p {
  font-size: 11px;
}
.date {
  display: flex;
  justify-content: space-between;
}
.link {
  width: 50%;
  border-radius: 8px;
  padding: 5px;
  margin-top: 16px;
  font-size: 12px;
  outline: none;
  border: 1px solid rgb(216, 214, 214);
  color: rgb(59, 57, 57);
  font-weight: bold;
}
.story {
  font-size: 14px;
  color: rgb(101, 101, 101);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}
@media screen and (max-width: 820px) {
  .header-img {
    height: 428.87px;
    display: block;
    width: 100%;
    margin: auto;
  }
  .header {
    height: 385px;
    padding: 20px;
    flex-direction: column;
    background-image: none;
    background-color: #001c1c;
  }
  .header-text {
    margin: auto;
    padding: 10px;
    margin-left: -10px;
  }
  .header-text span {
    margin: auto;
  }
  .buttons {
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .header-text .buttons a {
    padding: 8px 20px;
  }
  .trusted img {
    width: 80px;
    height: 80px;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    margin-top: 20px;
  }
  .img1 {
    display: none;
  }
  .img2 {
    display: block;
    margin: auto;
  }
  .header-text span {
    margin-top: -55px;
  }
  .item-list .items {
    height: 245.03px;
    background: #ffffff;
  }
  .item-list .items h5 {
    font-size: 20px;
  }
  .item-list .items p {
    font-size: 16px;
  }
  .buttons {
    margin: auto;
    margin-top: 130px;
  }
  .header-text p {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .header-text h1 {
    font-size: 25px;
    text-align: center;
    width: 100%;
  }
  .item-list .items:nth-child(2),
  .item-list .items:nth-child(5) {
    color: #000000;
    background: #ffffff;
  }
  .item-list .items {
    width: 100%;
  }
  .hire-text,
  .book-text {
    margin: auto;
    text-align: left;
    padding: 10px;
  }
  .book-text h1,
  .hire-text h1 {
    font-size: 25px;
  }
  .hire,
  .book {
    flex-direction: column;
    margin-top: 10px;
  }
  .book-img {
    display: none;
  }
  .hire-img {
    height: 250px;
    width: 90%;
    margin: auto;
    margin-top: 30px;
  }
  .book-img2 {
    height: 250px;
    width: 90%;
    margin-left: auto;
    display: block;
    margin-top: 30px;
  }
  .book-text p,
  .hire-text p {
    font-size: 17px;
  }
  .book-text a {
    margin: auto;
    width: 100px;
    padding: 10px 20px;
  }
  .book-text .btn12 {
    margin: auto;
    width: 40%;
  }
  .hire-text .btn12 {
    margin: auto;
    width: 40%;
  }
  .hire-text a {
    margin: auto;
    width: 100px;
    padding: 10px 30px;
  }
  .flow-list {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .flows-sm {
    display: block;
  }
  .flows-lg {
    display: none;
  }
  .flow {
    width: 90%;
    margin: auto;
    padding-top: 10px;
  }
  .flow h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  .flow p {
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    text-align: center;
  }
  .flow img {
    width: 180px;
    margin: auto;
  }
  .testimony {
    width: 100%;
    margin-bottom: -30px;
  }
  .testimony-item {
    width: 100%;
    height: 180px;
  }
  .testimony-item-text {
    width: 100%;
  }
  .testimony-item .img-item2,
  .testimony-item .img-item1,
  .testimony-item .img-item3 {
    width: 90%;
  }

  .testimony-item-text h2 {
    font-size: 14px;
  }
  .testimony-item-text p {
    font-size: 10px;
  }
  .testimony-item-text h3 {
    font-size: 12px;
  }
  .bottom {
    display: none;
  }
  .each-post1 {
    height: 200px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }
  .each-post1 .img {
    width: 100%;
    object-fit: cover;
  }
  .link {
    margin-top: 2px;
    width: 100%;
  }
}
</style>
