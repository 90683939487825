<template>
  <div>
    <Navbar />
    <div class="about">
      <div class="about-text">
        <h1>ABOUT GRANDILO</h1>
        <p>We break creative barriers and deliver working solutions</p>
      </div>
    </div>
    <div class="about-img"></div>
    <div class="header">
      <div class="grandilo">
        <span><h5>Grandilo</h5></span>
        <span></span>
      </div>

      <div class="header-text">
        <h1>We break creative barriers and deliver working solutions</h1>
        <p>
          Grandilo is an Information Technology and Consulting firm focused on
          Mobile, Web and Software Applications. We have proficient and
          dedicated teams grounded in Project Management and scalable software
          development. We have worked with multiple clients operating in various
          countries such as England, Nigeria, Uganda, Netherland, South-Africa
          and more. We value our clients and believe in giving them the best.
        </p>
        <div class="header-img1"></div>
        <p>
          We plan, design implement and go further to maintain all projects that
          comes our way. Our Clients reach us with diverse projects and we serve
          them without compromising on integrity, quality, dedication and
          accountability.
        </p>

        <router-link to="/workwithus"> Work With Us</router-link>
      </div>

      <div class="header-img2"></div>
    </div>

    <div class="work">
      <div class="work-text">
        <h1>Awards</h1>
        <p>
          Grandilo has been recognised as the "Most Trusted Custom Software Development Firm 2022 - Lagos" by AI Global Media


        </p>
        <p class="award mt-5" style="color: gray">
          Award: <img class="ml-3" src="../assets/images/Vector9.svg" alt="" />
        </p>
      </div>

      <div class="work-img"></div>
    </div>

    <div class="team">
      <div class="title">
        <h1>Our Team</h1>
        <p>Meet the Grandilonites</p>
      </div>
      <div class="team-members">
        <div class="member">
          <img src="../assets/images/Frame132.svg" alt="" />
          <h1>Ilo Calistus</h1>
          <p>CEO</p>
        </div>
        <div class="member">
          <img src="../assets/images/Frame128.svg" alt="" />
          <h1>Jude</h1>
          <p>Lead Developer</p>
        </div>
        <div class="member">
          <img src="../assets/images/Frame.svg" alt="" />
          <h1>Eloka</h1>
          <p>Product Design Lead</p>
        </div>
        <div class="member">
          <img src="../assets/images/Frame130.svg" alt="" />
          <h1>Augustine</h1>
          <p>Full Stack Developer</p>
        </div>
        <div class="member">
          <img src="../assets/images/Frame131.svg" alt="" />
          <h1>Peter</h1>
          <p>Frontend Developer</p>
        </div>
        <div class="member">
          <img src="../assets/images/Frame129.svg" alt="" />
          <h1>Ifeanyi Nwabuokei</h1>
          <p>Project Manager</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";

export default defineComponent({
  /* eslint-disable */
  name: "About",
  components: {
    Navbar,
    Footer,
  },
  setup() {},
});
</script>

<style scoped>
.about {
  background-image: linear-gradient(
      to bottom,
      rgba(11, 71, 65, 0.45),
      rgba(11, 71, 65, 0.45)
    ),
    url(../assets/images/banner11.svg);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  height: 85vh;
  color: #ffff;
}
.about .about-text {
  margin: auto;
  width: 70%;
  padding-top: 250px;
  text-align: left;
}
.about-text h1 {
  font-style: normal;
  color: #fff;
  font-family: "Prompt", sans-serif;
  font-weight: bolder;
  font-weight: 700;
  font-size: 72px;
}
.about-text p {
  font-weight: 400;
  font-size: 20px;
  margin-top: -12px;
}
.about-img {
  height: 40vh;
  margin: auto;
  display: none;
  /* margin-right: 65px; */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../assets/images/banner11.svg);
  width: 100%;
}
.header {
  background: #263238;
  height: 550px;
  padding: 30px;
  display: flex;
  /* justify-content: space-between; */
}
.header-text {
  width: 50%;
  margin: auto;
  color: #ffffff;
  text-align: left;
}
.header-text p {
  font-size: 17px;
  font-weight: 100;
}
.header-text a {
  font-weight: 600;
  font-size: 14px;
  left: 100px;
  text-decoration: none;
  padding: 10px 30px 10px 30px;
  outline: none;
  text-align: center;
  color: #ffffff;
  border: 1px solid #199a8b;
  background: #199a8b;
  border-radius: 5px;
}
.header-text a:hover {
  background-color: #ffff;
  color: #199a8b;
}
.header-text h1 {
  font-weight: 700;
  font-size: 25px;
  width: 80%;
}
.header .grandilo {
  width: 10%;
  padding-left: 20px;
  display: flex;
  justify-content: left;
  margin: auto;
}
.header .grandilo span {
  width: 12px;
  background-color: #199a8b;
  height: 390px;
}
.header .grandilo span:first-child {
  margin-top: 74px;
  margin-right: 10px;
}
.header .grandilo span h5 {
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  margin-top: -20px;
  transform: rotate(-90deg);
}
.header .header-img2 {
  margin: auto;
  overflow: hidden;
  margin-right: -30px;
  background-image: url(../assets/images/olav-ahrens-rotne-MAEbdaFe4ps-unsplash.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  width: 35%;
}
.header .header-img1 {
  display: none;
  margin: auto;
  overflow: hidden;
  margin-right: -30px;
  background-image: url(../assets/images/olav-ahrens-rotne-MAEbdaFe4ps-unsplash.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  width: 35%;
}
.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}
.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}
.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}
.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}
.work {
  display: flex;
  justify-content: space-between;
  margin: auto;
  height: 430px;
}
.work-text {
  margin: auto;
  margin-left: 50px;
  align-content: center;
  width: 45%;
  text-align: left;
}
.work-text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  color: #000000;
}
.work-text p {
  font-style: normal;
  font-weight: 100;
  width: 90%;
  font-size: 20px;
  color: #000000;
}
.work-img {
  background-image: url(../assets/images/desola-lanre-ologun-IgUR1iX0mqM-unsplash.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 55%;
}
.title {
  margin: 80px 0 20px 0;
}
.title h1 {
  font-weight: 600;
  font-size: 33px;
  text-align: center;
}
.title p {
  font-weight: 100;
  font-size: 20px;
  margin-top: -10px;
  text-align: center;
}
.team-members {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}
.member img {
  width: 220px;
  height: 220px;
  margin: 40px;
}
.member h1 {
  font-weight: 800;
  font-size: 17px;
  margin-top: -30px;
  text-align: center;
}
.member p {
  font-weight: 100;
  font-size: 19px;
  margin-top: -10px;
  text-align: center;
}
@media screen and (max-width: 807px) {
  .about {
    background: #04332f;
    height: 310px;
  }
  .about-img {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 260px;
  }
  .about .about-text {
    margin: auto;
    width: 90%;
    padding-top: 120px;
    text-align: center;
  }
  .about-text h1 {
    font-size: 30px;
  }
  .about-text p {
    font-size: 16px;
    margin-top: 12px;
  }
  .work {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin: auto;
    height: auto;
  }
  .work-text {
    margin: auto;
    align-content: flex-start;
    margin-left: 0px;
    width: 100%;
    text-align: left;
  }
  .work-text h1 {
    font-size: 28px;
  }
  .work-text p {
    width: 100%;
    margin: auto;
    font-size: 20px;
  }
  .award {
    display: none;
  }
  .work-img {
    margin: auto;
    width: 100%;
    height: 250px;
    display: block;
  }
  .header .grandilo {
    display: none;
  }
  .header {
    background: transparent;
    padding: 20px;
    height: auto;
    flex-direction: column;
  }
  .header-text {
    width: 100%;
    margin: auto;
    color: black;
    text-align: left;
  }
  .header-text p {
    font-size: 20px;
    font-weight: 100;
  }
  .header-text h1 {
    font-size: 28px;
    width: 100%;
  }
  .header-text a {
    display: none;
  }
  .header .header-img2 {
    display: none;
  }
  .header .header-img1 {
    display: block;
    margin: auto;
    margin-bottom: 30px;
    align-items: flex-start;
    margin-right: 0px;
    height: 250px;
    width: 100%;
  }
  .team-members {
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
  }
  .member img {
    width: 250px;
    height: 250px;
    margin: 20px;
  }
  .member h1 {
    font-size: 24px;
    margin-top: -10px;
  }
  .member p {
    font-size: 20px;
  }
  .bottom {
    display: none;
  }
}
</style>
