import { FieldValue, serverTimestamp } from "firebase/firestore";

export class bookingModel {
  email: string;
  phone_num: string;
  date: string;
  purpose: string;
  company_name: string;
  full_name: string;
  time: string;
  createdAt: FieldValue;

  constructor() {
    this.email = "";
    this.phone_num = "";
    this.date = "";
    this.purpose = "";
    this.company_name = "";
    this.full_name = "";
    this.time = "";
    this.createdAt = serverTimestamp();
  }
}
