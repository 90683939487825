<template>
  <Navbar />
  <div class="home">
    <div class="header row">
      <div class="header-text col-md-8 col-sm-10">
        <h1>Book an <strong style="color: red">Advisory</strong> Session</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum
          tempora reprehenderit corporis id expedita. Eveniet fuga voluptas
          reprehenderit voluptatem quos ad deserunt.
        </p>
      </div>
    </div>
    <div class="header-img"></div>
    <h1 class="form-text">Book a Session</h1>
    <div class="form">
      <form class="book-form" @submit.prevent="bookings()">
        <div class="form-item">
          <label for="fullname">Full Name</label>
          <input
            type="text"
            v-model="booking.full_name"
            placeholder="Enter Full Name"
            id="fullname"
          />
          <span
            v-for="error in v$.full_name.$errors"
            :key="error.$uid"
            class="error"
            >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="email">Email</label>
          <input
            type="email"
            v-model="booking.email"
            placeholder="Enter your email"
            id="email"
          />
          <span
            v-for="error in v$.email.$errors"
            :key="error.$uid"
            class="error"
            >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="number">Phone Number</label>
          <input
            type="tel"
            v-model="booking.phone_num"
            placeholder="Enter Phone Number"
            id="number"
          />
          <span
            v-for="error in v$.phone_num.$errors"
            :key="error.$uid"
            class="error"
            >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="number">Company Name</label>
          <input
            type="text"
            v-model="booking.company_name"
            placeholder="Enter name of Your Company"
            id="number"
          />
          <span
            v-for="error in v$.company_name.$errors"
            :key="error.$uid"
            class="error"
            >{{ error.$message }}</span
          >
        </div>

        <div class="row">
          <div class="form-item col-md-5">
            <label for="date">Date</label>
            <input
              type="date"
              v-model="booking.date"
              placeholder="Enter name of Your Company"
              id="date"
            />
            <span
              v-for="error in v$.date.$errors"
              :key="error.$uid"
              class="error"
              >{{ error.$message }}</span
            >
          </div>

          <div class="form-item col-md-5">
            <label for="time">Time</label>
            <input type="time" v-model="booking.time" id="time" />
            <span
              v-for="error in v$.time.$errors"
              :key="error.$uid"
              class="error"
              >{{ error.$message }}</span
            >
          </div>
        </div>
        <div class="form-item">
          <label for="purpose">Purpose</label>
          <textarea
            name="purpose"
            v-model="booking.purpose"
            id="purpose"
            placeholder="Purpose for Session"
          ></textarea>
          <span
            v-for="error in v$.purpose.$errors"
            :key="error.$uid"
            class="error"
            >{{ error.$message }}</span
          >
        </div>

        <button class="mt-3" type="submit">{{ loader }}</button>
      </form>
    </div>
    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import { defineComponent, reactive, computed, toRefs } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { store } from "@/store";
import { bookingModel } from "@/models/booking";
import router from "@/router";
import Util from "@/util/Util";

export default defineComponent({
  /* eslint-disable */
  name: "Book",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const state = reactive({
      loader: "Submit Form",
      booking: new bookingModel(),
    });

    const rules = computed(() => {
      return {
        email: { required, email },
        phone_num: { required },
        date: { required },
        purpose: { required },
        company_name: { required },
        full_name: { required },
        time: { required },
      };
    });
    const loading = computed(() => store.getters["booking/getLoading"]);
    const v$ = useVuelidate(rules, state.booking);

    async function bookings() {
      const result = await v$.value.$validate();
      if (!result) {
      } else {
        try {
          state.loader = "Submitting.....";
          await store.dispatch("booking/submitBooking", state.booking);
          state.booking = new bookingModel();
          Util.sweetAlert2(
            "Success Sent",
            "We will get back to you in less than 24 hours",
            "success",
            7000
          );
          state.loader = "Submit Form";
          await router.push({ path: "/" });
        } catch (err: any) {
          state.loader = "Submit Form";
          Util.sweetAlert2("Error Encountered", err.message, "error", 4000);
        }
      }
    }

    return {
      bookings,
      ...toRefs(state),
      rules,
      v$,
      loading,
    };
  },
});
</script>

<style scoped>
.header {
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(4, 51, 47, 0.45),
      rgba(4, 51, 47, 0.45)
    ),
    url(../assets/images/christina1.svg);
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
  height: 85vh;
}
.header-text {
  margin: auto;
  padding-top: 180px;
  color: #ffffff;
  text-align: left;
}
.header-text p {
  width: 70%;
  font-weight: 400;
  font-size: 20px;
}
.header-text h1 {
  width: 100%;
  font-weight: 700;
  font-size: 60px;
}
.header-img {
  height: 55vh;
  margin: auto;
  display: none;
  /* margin-right: 65px; */
  background-position: center;
  background-size: cover;
  background-image: url(../assets/images/christina1.svg);
  width: 50%;
}
.book-form {
  width: 55%;
  margin: auto;
  margin-bottom: 100px;
  height: auto;
  padding: 40px;
  border-radius: 3px;
}

.book-form .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-item label {
  text-align: left;
  font-size: 16px;
  color: black;
  font-family: sans-serif;
  font-weight: 600;
}

.form-item input,
.form-item textarea {
  border-radius: 6px;
  margin-top: -5px;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  font-size: 14px;
  border: none;
  background-color: #ececec;
}

.form-text {
  color: black;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 30px;
  text-align: center;
}

.book-form button {
  width: 150px;
  float: left;
  text-align: center;
  padding: 8px;
  font-weight: 600;
  outline: none;
  background-color: #009688;
  color: #fff;
  border: 1px solid #009688;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.book-form button:hover {
  background: #ffffff;
  color: #009688;
}
.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}
.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}
.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}
.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}
.error {
  color: rgb(201, 6, 6);
  font-size: 12px;
  text-align: left;
}
@media screen and (max-width: 807px) {
  .book-form {
    width: 100%;
    padding: 20px;
  }
  .book-form button {
    float: none;
  }
  .bottom span:first-child {
    height: 350px;
    margin-right: 45px;
    margin-top: -40px;
    border-top-right-radius: 20px;
  }
  .form-text {
    margin-top: 50px;
  }
  .bottom span:last-child {
    height: 250px;
    margin-right: 10px;
    border-top-right-radius: 20px;
  }
  .header-img {
    height: 260.33px;
    width: 100%;
    display: block;
    margin: auto;
  }
  .header {
    background-image: none;
    margin-bottom: 0px;
    height: 385px;
    background-color: #04332f;
  }

  .header-text {
    margin: auto;
    text-align: center;
    padding: 50px 11px 10px 11px;
    margin-left: 0;
  }
  .bottom {
    display: none;
  }
  .header-text p {
    font-size: 16px;
    width: 90%;
    margin: auto;
  }
  .form-text {
    font-size: 24px;
    margin-top: 20px;
  }
  .header-text h1 {
    margin-top: 30px;
    font-size: 40px;
  }
}
</style>
