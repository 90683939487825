<template>
  <div>
    <Navbar/>
    <div class="work">
      <div class="work-text">
        <h1>
          Build the Future with <span style="color: rgba(0, 150, 136, 1)">GRANDILO</span>
        </h1>
        <p class="ml-2">
          Your vision, our innovation. Let's craft exceptional Software experiences together.
        </p>
      </div>
    </div>
    <div class="form">
      <form class="work-form" @submit.prevent="work_with_us()">
        <div class="form-item-last">
          <label style="font-weight: 600">Project Type</label>
          <div class="radio">
            <input
                class="mr-2"
                id="New"
                type="radio"
                v-model="work.isNewProject"
                :value="true"
                name="Project"
            /><label for="New" class="mr-5">New Project</label>
            <input
                class="mr-2"
                id="Existing"
                type="radio"
                v-model="work.isNewProject"
                :value="false"
                name="Project"
            /><label for="Existing">Existing Project</label>
          </div>
        </div>
        <div class="form-item-last">
          <label style="font-weight: 600"
          >What services are you interested in?</label
          >
          <div class="check">
            <div>
              <input
                  type="checkbox"
                  class="mr-2"
                  value="Web App"
                  name="Web App"
                  v-model="work.services"
                  id="4"
              /><label for="4">Web App</label>
            </div>
            <div>
              <input
                  type="checkbox"
                  class="mr-2"
                  value="Mobile App"
                  name="Mobile App"
                  v-model="work.services"
                  id="5"
              /><label for="5">Mobile App</label>
            </div>
            <div>
              <input
                  type="checkbox"
                  class="mr-2"
                  name="Website Design"
                  value="Website Design"
                  v-model="work.services"
                  id="6"
              /><label for="6">Website Design</label>
            </div>
            <div>
              <input
                  type="checkbox"
                  class="mr-2"
                  name="Api Development"
                  value="Api Development"
                  v-model="work.services"
                  id="7"
              /><label for="7">Api Development</label>
            </div>
            <div>
              <input
                  type="checkbox"
                  class="mr-2"
                  name="Brand Identity Design"
                  value="Brand Identity Design"
                  v-model="work.services"
                  id="8"
              /><label for="8">Brand Identity Design</label>
            </div>
            <div>
              <input
                  type="checkbox"
                  class="mr-2"
                  name="Other"
                  value="Other"
                  v-model="work.services"
                  id="9"
              /><label for="9">Other</label>
            </div>
            <span
                v-for="error in v$.services.$errors"
                :key="error.$uid"
                class="error"
            >{{ error.$message }}</span
            >
          </div>
        </div>
        <div class="form-item-last">
          <label style="font-weight: 600"
          >Do you have any functional documents?</label
          >
          <div class="radio">
            <input
                class="mr-2"
                id="yes"
                type="radio"
                name="Yes"
                v-model="work.haveFunctionalDocument"
                :value="true"
                checked
            /><label for="yes" class="mr-5">Yes</label>
            <input
                class="mr-2 ml-5"
                id="no"
                type="radio"
                :value="false"
                name="Yes"
                v-model="work.haveFunctionalDocument"
            /><label for="no">No</label>
          </div>
        </div>
        <div class="form-item" v-if="work.haveFunctionalDocument">
          <label for="country"
          >What are the functional document you have currently</label
          >
          <select name="" v-model="work.currentFunctionalDocument" id="current">
            <option value=""></option>
            <option value="I have the UI/UX designs and I can share them">
              I have the UI/UX designs and I can share them
            </option>
            <option value="I have the wireframes, the designs are in process">
              I have the wireframes, the designs are in process
            </option>
            <option
                value="I have the wireframes, I want your team to help with the designs"
            >
              I have the wireframes, I want your team to help with the designs
            </option>
            <option
                value="I just have a write-up ready and will be needing further
              discussion"
            >
              I just have a write-up ready and will be needing further
              discussion
            </option>
            <option value="Others">Others</option>
          </select>
        </div>
        <h1 class="form-text">Contact information</h1>

        <div class="form-item">
          <label for="fullname">Full Name</label>
          <input
              type="text"
              v-model="work.fullName"
              placeholder="Enter Full Name"
              id="fullname"
          />
          <span
              v-for="error in v$.fullName.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="email">Email</label>
          <input
              type="email"
              v-model="work.email"
              placeholder="Enter email"
              id="email"
          />
          <span
              v-for="error in v$.email.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="country">Country</label>
          <select name="" v-model="work.country" id="country">
            <option value="">Select your Country</option>
            <option v-for="(country, index) in countries" :key="index" :value="country">{{ country }}</option>
          </select>
          <span
              v-for="error in v$.country.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="phone">Phone Number</label>
          <input
              type="tel"
              v-model="work.phone"
              placeholder="Enter Phone Number"
              id="phone"
          />
          <span
              v-for="error in v$.phone.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="companyName">Company Name</label>
          <input
              type="text"
              v-model="work.companyName"
              placeholder="Enter your Company name"
              id="companyName"
          />
        </div>

        <div class="form-item">
          <label for="message">Project Description</label>
          <textarea
              name="message"
              v-model="work.projectDescription"
              id="message"
              placeholder="Enter your message"
          ></textarea>
          <span
              v-for="error in v$.projectDescription.$errors"
              :key="error.$uid"
              class="error"
          >{{ error.$message }}</span
          >
        </div>

        <div class="form-item">
          <label for="remark">Any other remark? (optional)</label>
          <textarea
              name="message"
              v-model="work.remark"
              id="remark"
              placeholder="Enter your message"
          ></textarea>
        </div>

        <div class="form-item">
          <label for="aboutUs"
          >We're curious, how did you hear about us? (optional)</label
          >
          <select name="" v-model="work.aboutUs" id="aboutUS">
            <option value="">Tell us, how you heard about us</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="A Friend">Recommended by a friend</option>
            <option value="Search Engine">Search Engine(Google, Yahoo, etc)</option>
            <option value="Social Media">Social Media</option>
            <option value="Blog or publication">Blog or publication</option>
            <option value="other">Other</option>
          </select>
        </div>
        <button class="mt-3" type="submit"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span><span
            v-else>Submit Request</span>
        </button>
      </form>
    </div>
    <div class="bottom">
      <span></span>
      <span></span>
    </div>
    <Footer/>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, reactive, toRefs, watch} from "vue";
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";
import {countries} from "@/countries/countries";
import {useVuelidate} from '@vuelidate/core'
import {required, email} from "@vuelidate/validators";
import {WorkWithUsModel} from "@/models/workwithus";
import router from "@/router";
import {useStore} from "vuex";
import Util from "@/util/Util";

export default defineComponent({
  /* eslint-disable */
  name: "WorkWithUs",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      work: new WorkWithUsModel(),
    });

    const rules = computed(() => {
      return {
        country: {required},
        email: {required, email},
        phone: {required},
        isNewProject: {required},
        haveFunctionalDocument: {required},
        /* currentFunctionalDocument: {required},*/
        services: {required},
        /* companyName: {required},*/
        fullName: {required},
        projectDescription: {required},
      }
    });

    const v$ = useVuelidate(rules.value, state.work);
    const loading = computed(() => store.getters["workwithus/getLoading"]);

    async function work_with_us() {
      //  await router.push({path: "/thankyou"});

      const result = await v$.value.$validate();
      if (!result) {
        return false;
      } else {
        try {
          await store.dispatch("workwithus/submitWork", state.work);
          // state.work = new WorkWithUsModel();
          await router.push({path: "/thankyou"});
        } catch (err: any) {
          Util.sweetAlert2("Failed to submit proposal", err.message, "error", 4000);
        }
      }
    }

    watch(() => state.work.haveFunctionalDocument, (newValue) => {
      if (!newValue) {
        state.work.currentFunctionalDocument = '';
      }
    })
    return {
      work_with_us,
      // select,
      v$,
      loading,
      ...toRefs(state),
      rules,
      countries,
    };
  },
});
</script>

<style scoped>
.work {
  background: #052b2b;
  height: 85vh;
  color: #ffff;
}

.work .work-text {
  width: 70%;
  margin: auto;
  padding-top: 200px;
  text-align: left;
}

.work-text h1 {
  font-style: normal;
  color: #fff;
  font-weight: 700;
  font-size: 72px;
}

.work-text p {
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 20px;
  margin-top: -12px;
}

.bottom {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-top: -150px;
  overflow: hidden;
  margin-bottom: -150px;
}

.bottom span {
  width: 13px;
  height: 200px;
  transform: rotate(45deg);
  background-color: #199a8b;
}

.bottom span:first-child {
  height: 350px;
  margin-right: 45px;
  margin-top: -40px;
  border-top-right-radius: 20px;
}

.bottom span:last-child {
  height: 250px;
  margin-right: 50px;
  border-top-right-radius: 20px;
}

.work-form {
  width: 55%;
  margin: auto;
  margin-bottom: 150px;
  height: auto;
  padding: 40px;
  border-radius: 3px;
}

.work-form .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-item-last {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

.form-item-last label {
  text-align: left;
  font-size: 16px;
  color: black;
  font-family: sans-serif;
  font-weight: 400;
}

input[type="radio"] {
  width: 14px;
  height: 14px;
  appearance: none;
  border: 1px solid #353636;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
}

input[type="radio"]:checked {
  background-color: #009688;
}

input[type="checkbox"] {
  accent-color: #009688;
}

.check {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
}

.check div {
  margin-right: 40px;
}

.form-item label {
  text-align: left;
  font-size: 16px;
  color: black;
  font-family: sans-serif;
  font-weight: 600;
}

.form-item select {
  outline: none;
  margin-top: -5px;
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
  border: none;
  background-color: #ececec;
}

.work-item select option,
.work-item select,
.work-item input::placeholder {
  font-size: 14px;
}

.form-item input,
.form-item textarea {
  border-radius: 6px;
  margin-top: -5px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  border: none;
  background-color: #ececec;
}

.form-text {
  color: black;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: left;
}

.work-form button {
  width: 150px;
  float: left;
  text-align: center;
  padding: 8px;
  font-weight: 600;
  outline: none;
  background-color: #009688;
  color: #fff;
  border: 1px solid #009688;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.work-form button:hover {
  background: #ffffff;
  color: #009688;
}

.error {
  color: rgb(201, 6, 6);
  font-size: 12px;
  text-align: left;
}

@media screen and (max-width: 807px) {
  .work {
    height: 425px;
    background: linear-gradient(
        to bottom,
        rgba(11, 71, 65, 0.45),
        rgba(11, 71, 65, 0.45)
    ),
    url(../assets/images/cytonn-photography-n95VMLxqM2I-unsplash.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .work .work-text {
    margin: auto;
    padding-top: 180px;
    width: 100%;
    text-align: center;
  }

  .work-text h1 {
    font-size: 30px;
  }

  .work-text p {
    font-size: 15px;
  }

  .work-form {
    width: 100%;
    padding: 20px;
  }

  .work-form button {
    float: none;
  }

  .work-form {
    margin-bottom: 30px;
  }

  .bottom {
    display: none;
  }
}
</style>
