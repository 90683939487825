import { workWithUs } from "@/config/firebase";
import { WorkWithUsModel } from "@/models/workwithus";
import { getErrorMessage } from "@/util/firebaseErrorCodes";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  orderBy,
} from "firebase/firestore";

const state = {
  loading: false,
  applications: [] as WorkWithUsModel[],
  singleApplication: { id: "", data: {} },
  unsub: null,
};

const getters = {
  getLoading: (state: any) => state.loading,
  getApplication: (state: any) => state.applications,
  getSingleApplication: (state: any) => state.singleApplication,
};
const mutations = {
  setLoading(state: any, payload: any) {
    state.loading = payload;
  },
  setApplication(state: any, payload: any) {
    state.application = payload;
  },
  setSingleApplication(state: any, payload: any) {
    state.singleApplication = payload;
  },
};
const actions = {
  async submitWork({ commit }: any, work: WorkWithUsModel) {
    try {
      commit("setLoading", true);
      await addDoc(workWithUs, Object.assign({}, work));
      return Promise.resolve(null);
    } catch (e: any) {
      return Promise.reject({
        message: e.code ? getErrorMessage(e.code) : e.message,
      });
    } finally {
      commit("setLoading", false);
    }
  },
  getApplication({ state, commit }: any) {
    const trr_arr: WorkWithUsModel[] = [];
    const docRef = query(workWithUs, orderBy("dateCreated", "desc"));
    commit("setLoading", true);
    state.unsub = onSnapshot(
      docRef,
      (snapshot) => {
        snapshot.forEach((doc) => {
          trr_arr.push(WorkWithUsModel.toFirestore(doc));
        });
        commit("setLoading", false);
        state.applications = trr_arr;
      },
      (error) => {
        commit("setLoading", false);
        console.log(error.message);
      }
    );
  },
  async getSingleApplication({ commit }: any, id: string) {
    const docRef = doc(workWithUs, id);
    const Snapshot = await getDoc(docRef);
    if (Snapshot.exists()) {
      commit("setSingleApplication", {
        id: Snapshot.id,
        data: Snapshot.data(),
      });
    }
  },

  async updateApplication(
    { context }: any,
    singleApplication: WorkWithUsModel
  ) {
    try {
      // console.log(singleApplication);
      const docRef = doc(workWithUs, singleApplication.id);
      await updateDoc(docRef, singleApplication.toJSON());
      return Promise.resolve(null);
    } catch (e: any) {
      return Promise.reject({
        message: e.code ? getErrorMessage(e.code) : e.message,
      });
    }
  },

  async deleteApplication(id: string) {
    id = state.singleApplication.id;
    try {
      const docRef = doc(workWithUs, id);
      await deleteDoc(docRef);

      return Promise.resolve(null);
    } catch (e: any) {
      return Promise.reject({
        message: e.code ? getErrorMessage(e.code) : e.message,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
